import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import orm from '../../models/index';

// actions
import { appPropertySet } from '../../actions/AppProperty';
import { fetchPatientsVisitsDetailsSet } from '../../actions/PatientsVisitsDetails';

// mui
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import InputBase from '@material-ui/core/InputBase';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';

// react-bootstrap
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Tooltip from '@material-ui/core/Tooltip';
import MenuIcon from '@material-ui/icons/Menu';
import Menu from '@material-ui/core/Menu';

// functions
import { StrEncrypt, StrDecrypt } from '../../functions/FuncPerjer';

// components
import PatientData from './PatientData';
import VisitsData from './VisitsData';
import VisitsTypeTabs from './VisitsTypeTabs';

const session = orm.session();

class PatientEmrMain extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: '',
      hn: '',
      ptId: '',
      cidId: '',
      cidScKey: '',
      visitIconShow: null,
      MenuClose: false,
      econsent:null,
    };
    this.onChange = this.onChange.bind(this);
    this.onSearchFormPatients = this.onSearchFormPatients.bind(this);
    this.onSearchPatients = this.onSearchPatients.bind(this);
    this.visitIconShowChange = this.visitIconShowChange.bind(this);
    this.visitIconShowClose = this.visitIconShowClose.bind(this);
    this.visitIconShowClose = this.visitIconShowClose.bind(this);
    this.disableSearchPt = this.disableSearchPt.bind(this);
  }

  visitIconShowChange(event) {
    this.setState({ MenuClose: true });
    this.setState({ visitIconShow: event.currentTarget });
  }

  visitIconShowClose() {
    this.setState({ visitIconShow: null });
  }

  onChange(e) {
    const re = /^[0-9\b]+$/;
    if (e.target.value === '' || re.test(e.target.value)) {
      this.setState({ value: e.target.value });
    }
  }

  disableSearchPt(e) {
    e.preventDefault();
    alert('Function การค้นหาถูกปิดการใช้งาน');
  }

  onSearchFormPatients(e) {
    e.preventDefault();

    let bid2 = '';
    let hn = '';
    let cid = '';

    this.setState({ visitIconShow: null });
    this.setState({ hn: '' });
    this.setState({ ptId: '' });
    this.setState({ cidId: '' });
    this.setState({ cidScKey: '' });

    if (this.state.value.trim().length > 0) {
      if (this.state.value.trim().length === 9 || this.state.value.trim().length === 7) {
        hn = this.state.value;
      }

      if (this.state.value.trim().length === 13) {
        cid = this.state.value;
      }

      if (cid <= '') {
        alert('กรุณากรอก CID ไม่ถูกต้อง !');
      } else {
        let dataRequest = {
          idToken: this.props.appData.idToken,
          cid: cid,
          hn: hn,
          hospCode: this.props.appData.loginData.hospCode,
          limit: this.props.appProperty.visitLimit,
        };

        let res = this.props.fetchPatientsVisitsDetailsSet(dataRequest);

        let obj = this;
        res.then(
          function (v) {
            let cidEncrypted2 = StrEncrypt(cid, obj.props.appProperty.ScKey);

            console.log(v.value.emrData);

            if (v.value.emrData.patients) {
              obj.setState({ cidScKey: cidEncrypted2 });

              obj.setState({ value: v.value.emrData.patients.bid });

              obj.props.history.push('/patient-emr/' + cidEncrypted2 + bid2);

              obj.setState({ hn: v.value.emrData.patients.data.hn[0].hn });
              obj.setState({ ptId: v.value.emrData.patients._id });
              obj.setState({ cidId: v.value.emrData.patients.bid });
            } else {
              alert('ไม่พบข้อมูล CID : ' + cid);
            }

            window.scroll(0, 0);
          },
          function (e) {
            // TypeError: Throwing
            if (e.status !== 200) {
              alert('การค้นห้าข้อมูลมีปัญหากรุณาลองใหม่อีกครั้ง !');
            } else {
            }
          }
        );
      }
    } else {
      alert('กรุณากรอก CID หรือ HN !');
    }
  }

  onSearchPatients() {
    const { params } = this.props.match;

    let bid2 = '';
    let hn = '';
    let cid = '';
    let cidText = '';
    let ccid = '';

    if (params.cid) {
      cidText = params.cid;

      ccid = StrDecrypt(cidText, this.props.appProperty.ScKey);
    }

    if (params.bid && ccid === this.state.value) {
      bid2 = '/' + params.bid;
    }

    if (this.state.value.trim().length > 0) {
      if (this.state.value.trim().length === 9 || this.state.value.trim().length === 7) {
        hn = this.state.value;
      }

      if (this.state.value.trim().length === 13) {
        cid = this.state.value;
      }

      if (hn <= '' && cid <= '') {
        alert('กรุณากรอก CID หรือ HN ไม่ถูกต้อง !');
      } else {
        let dataRequest = {
          idToken: this.props.appData.idToken,
          cid: cid,
          hn: hn,
          hospCode: this.props.appData.loginData.hospCode,
          limit: this.props.appProperty.visitLimit,
        };

        let res = this.props.fetchPatientsVisitsDetailsSet(dataRequest);

        let obj = this;
        res.then(
          function (v) {
            var cidEncrypted2 = StrEncrypt(cid, obj.props.appProperty.ScKey);

            if (params.cid) {
              obj.setState({ cidScKey: params.cid });
            } else {
              obj.setState({ cidScKey: cidEncrypted2 });
            }

            const visits = obj.props.appData.emrData.visits;

            session.Visits.delete({
              where(record) {
                return record.cid === v.value.emrData.patients.cid;
              },
            });

            for (var val of visits) {
              session.Visits.upsert(val);
            }

            let visitsData = session.Visits.all()
              .filter(Visits => Visits.cid === v.value.emrData.patients.cid)
              .orderBy(Visits => Visits.visitDateTime, 'desc')
              .toRefArray();

            if (bid2 === '' && visitsData.length > 0) {
              bid2 = '/' + visitsData[0].bid;
            }

            obj.props.history.push('/patient-emr/' + params.cid + bid2);

            obj.setState({ value: v.value.emrData.patients.bid });

            obj.setState({ hn: v.value.emrData.patients.data.hn[0].hn });
            obj.setState({ ptId: v.value.emrData.patients._id });
            obj.setState({ cidId: v.value.emrData.patients.bid });
            window.scroll(0, 0);
          },
          function (e) {
            // TypeError: Throwing
            if (e.status !== 200) {
              alert('การค้นห้าข้อมูลมีปัญหากรุณาลองใหม่อีกครั้ง !');
            }
          }
        );
      }
    } else {
      alert('กรุณากรอก CID หรือ HN !');
    }
  }

  componentDidMount() {
    const { params } = this.props.match;

    if (params.bid) {
      const appPropertyData = {
        visitSelect: params.bid,
        FetchingStatus: false,
      };

      this.props.appPropertySet(appPropertyData);
    }

    let cid = '';

    if (params.cid) {
      let cidText = params.cid;

      cid = StrDecrypt(cidText, this.props.appProperty.ScKey);
    }

    if (cid !== '') {
      if (cid.trim().length === 13) {
        this.setState({ value: cid });
        setTimeout(() => {
          if (this.props.appData.loginData) {
            // document.getElementById('SearchPtBt').click()
            this.setState({ cidScKey: params.cid });
            this.onSearchPatients();
          }
        }, 500);
      }
    }
  }

  componentDidUpdate() {
    if (this.props.width > 991 && this.state.visitIconShow !== null) {
      this.visitIconShowClose();
    }
    const { params } = this.props.match;

    if (!params.cid && this.state.hn) {
      this.setState({ value: '' });
      this.setState({ visitIconShow: null });
      this.setState({ hn: '' });
      this.setState({ ptId: '' });
      this.setState({ cidId: '' });
    }

    if (this.props.appProperty.visitMenuClose && this.state.MenuClose) {
      this.setState({ MenuClose: false });
      const appPropertyData = {
        visitMenuClose: false,
        FetchingStatus: false,
      };

      this.props.appPropertySet(appPropertyData);
      this.visitIconShowClose();
    }

    if (params.cid && this.state.value <= '') {
      let cid = '';

      if (params.cid) {
        let cidText = params.cid;

        cid = StrDecrypt(cidText, this.props.appProperty.ScKey);
      }

      if (cid !== '' && this.state.cidId !== cid) {
        if (cid.trim().length === 13) {
          this.setState({ value: cid });
          setTimeout(() => {
            if (this.props.appData.loginData) {
              this.setState({ cidScKey: params.cid });
              this.onSearchPatients();
            }
          }, 500);
        }
      }
    } else {
      if (params.cid) {
        let cid2 = null;

        let cidText2 = params.cid;

        cid2 = StrDecrypt(cidText2, this.props.appProperty.ScKey);

        if (cid2 && this.state.cidId) {
          if (cid2 !== this.state.cidId) {
            if (cid2.trim().length === 13) {
              this.setState({ hn: '' });
              this.setState({ ptId: '' });
              this.setState({ cidId: '' });
              this.setState({ value: cid2 });
              setTimeout(() => {
                if (this.props.appData.loginData) {
                  this.setState({ cidScKey: params.cid });
                  this.onSearchPatients();
                }
              }, 500);
            }
          }
        }
      }
    }
  }
  
  render() {
    return (
      <div>
        <AppBar
          position='fixed'
          className='box-shadow-custom MuiAppBar-color-custom patient-main'
          style={{ top: '55px' }}
        >
          <Toolbar variant='dense'>
            <div style={{ width: '170px' }}>
              <form style={{ width: '170px' }} onSubmit={this.onSearchFormPatients}>
                <InputBase
                  disabled={!this.props.appData?.permission?.viewEmr}
                  type='text'
                  id='cid-hn'
                  name='cid-hn'
                  value={this.state.value}
                  onChange={this.onChange}
                  placeholder='CID Search'
                  inputProps={{ 'aria-label': 'search', maxLength: 13 }}
                  endAdornment={
                    <InputAdornment position='end'>
                      <IconButton
                        id='SearchPtBt'
                        type='submit'
                        className='icon-button text-color'
                        aria-label='toggle password visibility'
                        edge='end'
                      >
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </form>
            </div>
            {this.props.width <= 991 && (
              <div style={{ marginLeft: 'auto' }}>
                <div className='float-right'>
                  <IconButton className='icon-button' onClick={this.visitIconShowChange}>
                    <Tooltip title='Visit'>
                      <MenuIcon />
                    </Tooltip>
                  </IconButton>

                  <Menu
                    id='visit-menu'
                    anchorEl={this.state.visitIconShow}
                    keepMounted
                    open={Boolean(this.state.visitIconShow)}
                    onClose={this.visitIconShowClose}
                    className='profile-menu visit-menu-custom'
                  >
                    <div style={{ width: '345px' }}>
                      <VisitsData
                        hn={this.state.hn}
                        ptId={this.state.ptId}
                        cid={this.state.cidId}
                        width={this.props.width}
                        height={this.props.height}
                        cidScKey={this.props.cidScKey}
                        token={this.props.appData.idToken}
                        widthSub={90}
                        maxHeight={200}
                      />
                    </div>
                  </Menu>
                </div>
              </div>
            )}
          </Toolbar>
        </AppBar>
        <div className='text-align-left' style={{ marginTop: '45px' }}>
          <Row>
            <Col lg={9}>
              <div style={{ overflow: 'hidden', wordWrap: 'break-word' }}>
                <div className='margin-bottom-8px'>
                  {this.state.ptId && (
                    <PatientData
                      hn={this.state.hn}
                      ptId={this.state.ptId}
                      cid={this.state.cidId}
                      width={this.props.width}
                    />
                  )}
                </div>
                {this.props.appProperty.visitSelect && this.state.ptId && (
                  <VisitsTypeTabs bid={this.props.appProperty.visitSelect} />
                )}
              </div>
            </Col>
            {this.props.width > 991 && (
              <Col lg={3}>
                <div
                  style={{
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    wordWrap: 'break-word',
                  }}
                >
                  {this.state.ptId && (
                    <VisitsData
                      hn={this.state.hn}
                      ptId={this.state.ptId}
                      cid={this.state.cidId}
                      width={this.props.width}
                      height={this.props.height}
                      cidScKey={this.props.cidScKey}
                      token={this.props.appData.idToken}
                      widthSub={100}
                      maxHeight={500}
                    />
                  )}
                </div>
              </Col>
            )}
          </Row>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    appProperty: state.appProperty,
    appData: state.appData,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    appPropertySet: appPropertyData => dispatch(appPropertySet(appPropertyData)),
    fetchPatientsVisitsDetailsSet: dataRequest => dispatch(fetchPatientsVisitsDetailsSet(dataRequest)),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PatientEmrMain));
