import {
  SUPERADMIN,
  ADMIN,
  DOCTOR,
  MANAGER,
  NURSE,
  PATIENT_ASSISTANT,
  REFERRAL_CENTER,
  REGISTRATION_CENTER,
  INSURANCE_CENTER,
} from '../constants/Users/userRole';

export const getPermission = ({ role }) => {
  const permission = {
    viewHospitalReport: false,
    viewProvinceReport: false,
    viewRefer: false,
    actionRefer: false,
    userManagement: false,
    hospitalManagement: false,
    addUserMember: false,
    viewEmr: false,
    viewBilling: false,
  };

  switch (role?.toUpperCase()) {
    case SUPERADMIN:
      permission.viewHospitalReport = true;
      permission.viewProvinceReport = true;
      permission.viewRefer = true;
      permission.actionRefer = true;
      permission.userManagement = true;
      permission.hospitalManagement = true;
      permission.addUserMember = true;
      permission.viewEmr = false;
      permission.viewBilling = false;
      break;
    case ADMIN:
      permission.viewHospitalReport = true;
      permission.viewProvinceReport = false;
      permission.viewRefer = true;
      permission.actionRefer = true;
      permission.userManagement = true;
      permission.hospitalManagement = false;
      permission.addUserMember = false;
      permission.viewEmr = true;
      permission.viewBilling = true;
      break;
    case DOCTOR:
    case MANAGER:
    case REGISTRATION_CENTER:
    case INSURANCE_CENTER:
      permission.viewHospitalReport = true;
      permission.viewProvinceReport = false;
      permission.viewRefer = true;
      permission.actionRefer = true;
      permission.userManagement = false;
      permission.hospitalManagement = false;
      permission.addUserMember = false;
      permission.viewEmr = true;
      permission.viewBilling = true;
      break;
    case NURSE:
    case PATIENT_ASSISTANT:
    case REFERRAL_CENTER:
      permission.viewHospitalReport = true;
      permission.viewProvinceReport = false;
      permission.viewRefer = true;
      permission.actionRefer = true;
      permission.userManagement = false;
      permission.hospitalManagement = false;
      permission.addUserMember = false;
      permission.viewEmr = false;
      permission.viewBilling = false;
      break;
    default:
      break;
  }

  return permission;
};
