import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import orm from '../../models/index';

// actions
import { appPropertySet } from '../../actions/AppProperty';
import { fetchPatientsVisitsDetailsSet } from '../../actions/PatientsVisitsDetails';

// react-bootstrap
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Alert from 'react-bootstrap/Alert';

// functions
import { ThaiDateTime } from '../../functions/FuncDateTimes';

// mui
import Card from '@material-ui/core/Card';

const session = orm.session();

function PtDental({ bid, appData, appPropertySet, fetchPatientsVisitsDetailsSet }) {
  const [visitData, setvisitData] = useState(null);
  const [bidData, setDataBid] = useState(false);

  const setDataBidFc = bid => {
    setDataBid(bid);
  };

  const setvisitDataFc = bid => {
    const visits = appData.emrData.visits;

    session.Visits.delete({
      where(record) {
        return record.bid === bid;
      },
    });

    for (var val of visits) {
      session.Visits.upsert(val);
    }

    let visitsData = session.Visits.all()
      .filter(Visits => Visits.bid === bid)
      .toRefArray();

    setvisitData({ visitsData });
  };

  useEffect(() => {
    if (bid !== bidData) {
      setDataBidFc(bid);
      setvisitDataFc(bid);
    }
  });

  return (
    <div>
      {visitData && (
        <div>
          {visitData.visitsData.map((items, index) => (
            <div key={index}>
              {items.dentals && (
                <Card variant='outlined' className='visit-detail-card'>
                  <Row>
                    <Col lg={12}>
                      <h5>Dental</h5>
                    </Col>
                  </Row>
                  {items.dentals.map((itemsDental, indexDental) => (
                    <Alert variant='info' key={indexDental} className='font-size-14px'>
                      <Row>
                        <Col lg={12}>LIST : {itemsDental.tmname}</Col>
                      </Row>

                      <Row>
                        <Col>
                          <div>ผลงาน : {itemsDental.tcount} ซี่</div>
                        </Col>
                        <Col>
                          <div>{itemsDental.scount} ด้าน</div>
                        </Col>
                        <Col>
                          <div>{itemsDental.rcount} ราก</div>
                        </Col>
                        <Col>
                          <div>{itemsDental.qtyCount} ชิ้น</div>
                        </Col>
                      </Row>

                      <Row>
                        <Col lg={6}>ICD10 : {itemsDental.icd}</Col>
                        <Col lg={6}>ICD9 : {itemsDental.icd9}</Col>
                      </Row>

                      <Row>
                        <Col lg={6}>Doctor : {itemsDental.doctorName}</Col>
                        <Col lg={6}>Date : {ThaiDateTime(itemsDental.visitDateTime)}</Col>
                      </Row>

                      <Row>{itemsDental.note && <Col lg={12}>หมายเหตุ : {itemsDental.note}</Col>}</Row>

                      <Row>{itemsDental.dentalNote && <Col lg={12}>Note : {itemsDental.dentalNote}</Col>}</Row>
                    </Alert>
                  ))}
                </Card>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

function mapStateToProps(state) {
  return {
    appProperty: state.appProperty,
    appData: state.appData,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    appPropertySet: appPropertyData => dispatch(appPropertySet(appPropertyData)),
    fetchPatientsVisitsDetailsSet: dataRequest => dispatch(fetchPatientsVisitsDetailsSet(dataRequest)),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PtDental));
