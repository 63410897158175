import React, { Component } from 'react';
import { connect } from 'react-redux';
import orm from '../../models/index';

// actions
import { fetchOnlineGatewayHosps } from '../../actions/OnlineGatewayHospitals';

// react-bootstrap
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

// functions
import { ThaiDateTime, diffMinutes, DateTimeNow } from '../../functions/FuncDateTimes';
import { shortHospName } from '../../functions/FuncPerjer';

// mui
import RefreshIcon from '@material-ui/icons/Refresh';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';

const session = orm.session();

class OnlineGatewayHosp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Token: '',
      OnlineGatewayHosps: [],
    };
    this.getOnlineGatewayHosps = this.getOnlineGatewayHosps.bind(this);
    this.setOnlineGatewayHosps = this.setOnlineGatewayHosps.bind(this);
  }

  getOnlineGatewayHosps() {
    let dataRequest = {
      idToken: this.props.appData.idToken,
    };

    let res = this.props.fetchOnlineGatewayHosps(dataRequest);

    let obj = this;
    res.then(
      function (v) {
        obj.setOnlineGatewayHosps();
      },
      function (e) {}
    );
  }

  setOnlineGatewayHosps() {
    let onlineGatewayData = this.props.appData.onlineGatewayHospital;

    session.TempData.delete({
      where(record) {
        return record.id !== '';
      },
    });

    let date2 = DateTimeNow();
    let df = null;
    for (let i = 0; i < onlineGatewayData.length; i++) {
      let val = onlineGatewayData[i];
      let existHospital = session.TempData.idExists(val.hospCode)
      if (!val.lastActive && existHospital) continue;
      df = diffMinutes(val.lastActive, date2);

      if (!df && df !== 0) {
        df = 999999;
      }

      if (this.props.appData.loginData && this.props.appData.loginData.hospCode === val.hospCode) {
        session.TempData.upsert({
          id: val.hospCode,
          hospCode: val.hospCode,
          hospName: val.hospName,
          gatewayVersion: val.gatewayVersion,
          lastActive: val.lastActive,
          diffMinutes: df,
          type: 1,
        });
      } else {
        session.TempData.upsert({
          id: val.hospCode,
          hospCode: val.hospCode,
          hospName: val.hospName,
          gatewayVersion: val.gatewayVersion,
          lastActive: val.lastActive,
          diffMinutes: df,
          type: 2,
        });
      }
    }

    let Datas = session.TempData.all()
      .orderBy(TempData => TempData.hospCode, 'asc')
      .orderBy(TempData => TempData.type, 'asc')
      .toRefArray();

    this.setState({ OnlineGatewayHosps: Datas });
  }

  componentDidMount() {
    this.getOnlineGatewayHosps();
  }

  render() {
    return (
      <div>
        <AppBar
          position='fixed'
          className='box-shadow-custom MuiAppBar-color-custom patient-main'
          style={{ top: '55px' }}
        >
          <Toolbar variant='dense' className='refer-bar'>
            <div className='text-align-left' style={{ width: '100%' }}>
              <Row>
                <Col lg={12} className='mb-lg-0 mb-sm-2 mb-md-2 mb-xs-2'>
                  <Row>
                    <Grid container item xs={3}>
                      <h6 className='margin-bottom-0px margin-top-8px' style={{ minWidth: '150px' }}>
                        Online hospital
                      </h6>
                    </Grid>

                    <Grid container item xs={9}>
                      <IconButton
                        className='icon-button avatar-button'
                        title='Refresh'
                        onClick={this.getOnlineGatewayHosps}
                      >
                        <RefreshIcon />
                      </IconButton>
                    </Grid>
                  </Row>
                </Col>
              </Row>
            </div>
          </Toolbar>
        </AppBar>

        {this.state.OnlineGatewayHosps.length > 0 && (
          <div className='text-align-left' style={{ marginTop: this.props.width <= 991 ? '65px' : '45px' }}>
            <Row>
              {this.state.OnlineGatewayHosps.map((items2, index2) => (
                <Col key={index2} lg={3} className='margin-bottom-8px'>
                  <Card
                    variant='outlined'
                    className={` card-refer 
										${items2.diffMinutes > 60 && items2.diffMinutes !== 999999 && 'referrals-card-rejected'} 
										${items2.diffMinutes <= 10 && 'referrals-card-complete'}`}
                  >
                    <CardHeader
                      className='card-header'
                      title={items2.hospCode + ' : ' + shortHospName(items2.hospName)}
                      avatar={
                        <Avatar aria-label='recipe' className='refer-type-icon-1'>
                          {this.state.OnlineGatewayHosps.length - index2}
                        </Avatar>
                      }
                    />
                    <CardContent className='card-content'>
                      <Row>
                        <Col lg={12}>
                          <Typography variant='body1' component='p'>
                            Gateway version : {items2.gatewayVersion}
                          </Typography>
                        </Col>

                        <Col lg={12}>
                          <Typography variant='body1' component='p' className='font-size-14px'>
                            Active : {ThaiDateTime(items2.lastActive)}
                          </Typography>
                        </Col>
                        <Col lg={12}>
                          <Typography variant='body1' component='p'>
                            Diff : {items2.diffMinutes} Minutes
                          </Typography>
                        </Col>
                      </Row>
                    </CardContent>
                  </Card>
                </Col>
              ))}
            </Row>
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    appProperty: state.appProperty,
    appData: state.appData,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchOnlineGatewayHosps: dataRequest => dispatch(fetchOnlineGatewayHosps(dataRequest)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(OnlineGatewayHosp);
