import React, { Component } from 'react';
import { connect } from 'react-redux';
import orm from '../../models/index';

// actions
import { fetchReferNotifications } from '../../actions/ReferNotifications';

// functions
import { delDays, DateTimeToStrDatetime } from '../../functions/FuncDateTimes';
import { StrEncrypt } from '../../functions/FuncPerjer';

// components
import WithHooksHOC from '../Utility/WithHooksHOC';

const session = orm.session();

class ReferNotifications extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.SetNotifications = this.SetNotifications.bind(this);
    this.GetReferNotifications = this.GetReferNotifications.bind(this);
    this.ReferNotifyTimer = this.ReferNotifyTimer.bind(this);
    this.ReferNotifyDelete = this.ReferNotifyDelete.bind(this);
  }

  SetNotifications() {
    const { update } = this.props.useIndexedDB('notifications');
    // add, getByIndex,

    let notifyData = this.props.appProperty.notifications;
    let maxDateID = delDays(new Date(), 3).getTime();

    if (notifyData) {
      let userId =
        this.props.appData.loginData.username.substring(4, 5) +
        this.props.appData.loginData.username.substring(2, 3) +
        this.props.appData.loginData.username.substring(1, 2) +
        this.props.appData.loginData.username.substring(5, 6) +
        this.props.appData.loginData.username.substring(3, 4) +
        this.props.appData.loginData.username.substring(6, 7) +
        this.props.appData.loginData.username.substring(7, 8) +
        this.props.appData.loginData.username.substring(10, 11) +
        this.props.appData.loginData.username.substring(9, 10) +
        this.props.appData.loginData.username.substring(8, 9) +
        this.props.appData.loginData.username.substring(0, 1);

      let vnLink = '';

      for (let val of notifyData) {
        if (new Date(val.referDateTime).getTime() >= maxDateID) {
          vnLink = '';

          if (val.vn) {
            vnLink = '/' + val.fromHospCode + '-' + val.vn;
          }

          update({
            id: val.bid + userId,
            keyId: val.bid + userId,
            key1: this.props.appData.loginData.hospCode,
            key2: '',
            notifyDateTime: DateTimeToStrDatetime(val.referDateTime),
            notifyType: 'refer',
            notifyLink: '/patient-emr/' + StrEncrypt(val.cid, this.props.appProperty.ScKey) + vnLink,
            datas: {
              bid: val.bid,
              nid: StrEncrypt(val.cid, this.props.appProperty.ScKey),
              fromHospCode: val.fromHospCode,
              toHospCode: val.toHospCode,
              fromHospName: val.fromHospName,
              toHospName: val.toHospName,
              hasRead: val.hasRead,
              icd: val.icd,
              receivedAt: val.receivedAt,
              referDateTime: val.referDateTime,
              referDateTimeInt: new Date(val.referDateTime).getTime(),
              status: val.status,
            },
            notifyRead: val.hasRead,
          });
        }
      }
    }
  }

  GetReferNotifications() {
    setTimeout(() => {
      let dataRequest = {
        idToken: this.props.appData.idToken,
        hospCode: this.props.appData.loginData.hospCode,
      };
      let res = this.props.fetchReferNotifications(dataRequest);

      let obj = this;
      res.then(function (v) {
        obj.SetNotifications();
      });
    }, 500);
  }

  ReferNotifyTimer() {
    if (this.props.appData.idToken) {
      this.GetReferNotifications();
    }
    setTimeout(() => {
      this.ReferNotifyTimer();
    }, 20000);
  }

  ReferNotifyDelete() {
    const { getAll, deleteRecord } = this.props.useIndexedDB('notifications');
    let QryData = [];

    getAll().then(FromDB => {
      session.TempData.delete({
        where(record) {
          return record._id !== '';
        },
      });

      for (var val of FromDB) {
        session.TempData.upsert(val);
      }

      let QryData2 = session.TempData.all()
        .filter(TempData => TempData.id !== '')
        .filter(TempData => TempData.datas.referDateTimeInt === undefined)
        .toRefArray();

      for (var val5 of QryData2) {
        deleteRecord(val5.id);
      }

      let maxDateID = delDays(new Date(), 2).getTime();

      QryData = session.TempData.all()
        .filter(TempData => TempData.id !== '')
        .filter(TempData => TempData.datas.referDateTimeInt < maxDateID)
        .toRefArray();

      for (var val2 of QryData) {
        deleteRecord(val2.id);
      }
    });
  }

  componentDidMount() {
    this.ReferNotifyTimer();
    this.ReferNotifyDelete();
  }

  componentDidUpdate() {
    // this.SetNotifications()
  }

  render() {
    return <div />;
  }
}

function mapStateToProps(state) {
  return {
    appProperty: state.appProperty,
    appData: state.appData,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchReferNotifications: dataRequest => dispatch(fetchReferNotifications(dataRequest)),
  };
}

export default WithHooksHOC(connect(mapStateToProps, mapDispatchToProps)(ReferNotifications));
