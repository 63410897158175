import axios from 'axios';
import { getPermission } from '../functions/Users';

// functions
import { getApiUrl } from "../functions/Commons";

export default idToken => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      const AuthStr = 'Bearer '.concat(idToken);

      axios
        .get(`${getApiUrl()}/auth/current`, {
          headers: { Authorization: AuthStr },
        })
        .then(res => {
          if (res.status === 200) {
            let data = {
              loginStatus: true,
              loginData: res.data,
              idToken: idToken,
              FetchingStatus: false,
              hospCode: res.data.hospCode,
              permission: getPermission({ role: res?.data?.role }),
            };
            return resolve(data);
          } else {
            let data = {
              loginStatus: false,
              loginData: res.data,
              idToken: idToken,
              FetchingStatus: false,
              hospCode: res.data.hospCode,
              permission: getPermission({ role: res?.data?.role }),
            };
            return resolve(data);
          }
        })
        .catch(err => {
          console.log('AXIOS ERROR: Token error !');
          return reject(err.response);
        });
    }, 200);
  });
};
