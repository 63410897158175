import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';

// constants
import { ADMIN, SUPERADMIN, roles } from '../../../constants/Users/userRole';

// style
import { UserFormContainer, Form } from './UserForm.style';

// actions
import { addUser, editUser, fetchHospitalList } from '../../../actions/UserManagement';

// components
import { ListboxComponent } from '../../Utility/ListboxComponent';
import TabPanel from '../../Utility/TabPanel';

// mui
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';
import { FormControlLabel } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import Alert from '@material-ui/lab/Alert';
import { CircularProgress, Grid, RadioGroup, Radio, Typography } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

const UserForm = props => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { control, watch, handleSubmit, errors } = useForm();

  const [loading, setLoading] = useState(false);
  const [selectedHospital, setSelectedHospital] = useState({});
  const [tab, setTab] = useState(0);

  const { location } = props;
  const user = location?.state?.user;
  const { appData } = useSelector(state => state);
  const { idToken, hospitalList, alert, loginData } = appData;

  const ActionBtn = () => {
    return (
      <div className='action'>
        <Button
          disabled={loading}
          className='cancel'
          variant='contained'
          onClick={() => history.push('/user-management')}
        >
          ยกเลิก
        </Button>
        <Button disabled={loading} type='submit' color='primary' variant='contained'>
          ยืนยัน
        </Button>
      </div>
    );
  };

  useEffect(() => {
    handleFetchHospitalList({ idToken });
  }, []);

  useEffect(() => {
    alert.type === 'success' && history.push('/user-management');
  }, [idToken, alert]);

  const handleFetchHospitalList = ({ idToken }) => {
    dispatch(fetchHospitalList({ idToken }));
  };

  const onSubmit = _user => {
    setLoading(true);

    if (user) {
      const body = {
        username: _user.username,
        password: _user.password,
        hospCode: user.hospCode !== selectedHospital.hospCode ? selectedHospital.hospCode : '',
        hospName: user.hospName !== selectedHospital?.hospName ? selectedHospital?.hospName : '',
        role: user.role !== _user.role ? _user.role : '',
        title: user.title !== _user.title ? _user.title : '',
        firstName: user.firstName !== _user.firstName ? _user.firstName : '',
        lastName: user.lastName !== _user.lastName ? _user.lastName : '',
        citizenship: _user.citizenship,
        empCode: _user.empCode,
      };

      dispatch(editUser({ idToken, body }));
    } else {
      const body = {
        username: _user.username,
        password: _user.password,
        hospCode: selectedHospital?.hospCode,
        hospName: selectedHospital?.hospName,
        role: _user.role,
        title: _user.title,
        firstName: _user.firstName,
        lastName: _user.lastName,
        citizenship: _user.citizenship,
        empCode: _user.empCode,
      };

      dispatch(addUser({ idToken, body }));
    }

    setLoading(false);
  };

  const onUpdatePassword = password => {
    if (password.newPassword !== password.confirmationPassword) return;

    setLoading(true);

    const body = {
      username: user?.username,
      password: password?.newPassword,
    };

    dispatch(editUser({ idToken, body }));

    setLoading(false);
  };

  return (
    <UserFormContainer>
      <Form elevation={2}>
        <Grid container spacing={1}>
          <Grid item xs={!user ? 12 : 6}>
            <h4 className='title'>{user ? 'แก้ไขผู้ใช้งาน' : 'ผู้ใช้งานใหม่'}</h4>
          </Grid>
          {user && (
            <Grid item xs={6}>
              <Tabs value={tab} onChange={(e, idx) => setTab(idx)}>
                <Tab label='Profile' />
                <Tab label='Password' />
              </Tabs>
            </Grid>
          )}
        </Grid>

        {/* SECTION: profile tab */}
        <TabPanel value={tab} index={0}>
          <form className='form' onSubmit={handleSubmit(onSubmit)}>
            <Controller
              as={
                <TextField
                  required
                  className='input'
                  label='รหัสผู้ใช้งาน'
                  variant='outlined'
                  disabled={loading || user}
                />
              }
              control={control}
              name='username'
              defaultValue={user?.username || ''}
            />

            {!user && (
              <Controller
                as={
                  <TextField
                    required
                    className='input'
                    label='รหัสผ่าน'
                    variant='outlined'
                    disabled={loading}
                    type='password'
                  />
                }
                control={control}
                name='password'
                defaultValue=''
              />
            )}

            <Autocomplete
              disableListWrap
              loading={!hospitalList[0]}
              ListboxComponent={ListboxComponent}
              options={
                loginData?.role?.toUpperCase() === SUPERADMIN
                  ? hospitalList
                  : hospitalList?.filter(item => item.hospCode === loginData?.hospCode)
              }
              defaultValue={
                user && {
                  hospCode: user?.hospCode,
                  hospName: user?.hospName,
                }
              }
              onChange={(option, value) => setSelectedHospital(value)}
              getOptionLabel={option => `${option.hospCode} : ${option.hospName}`}
              renderInput={params => (
                <TextField
                  {...params}
                  required
                  fullWidth
                  margin='normal'
                  label='โรงพยาบาล'
                  variant='outlined'
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {!hospitalList[0] ? <CircularProgress color='inherit' size={20} /> : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                />
              )}
            />

            <Controller
              as={<TextField select className='input' label='สิทธิ์' variant='outlined' disabled={loading} />}
              control={control}
              name='role'
              defaultValue={user ? user?.role?.toUpperCase() : ADMIN}
            >
              {roles?.map(item => (
                <MenuItem key={item.value} value={item.value}>
                  {item.label}
                </MenuItem>
              ))}
            </Controller>

            <Grid container spacing={2}>
              <Grid item xs={4} sm={2}>
                <Controller
                  as={
                    <TextField
                      select
                      fullWidth
                      // hidden={watch('role', user?.role || 'user') !== 'user'}
                      className='input'
                      variant='outlined'
                    />
                  }
                  control={control}
                  name='title'
                  defaultValue={user?.fullName?.title || 'นาย'}
                >
                  {[
                    { label: 'นาย', value: 'นาย' },
                    { label: 'นาง', value: 'นาง' },
                    { label: 'น.ส.', value: 'น.ส.' },
                  ].map(item => (
                    <MenuItem key={item.value} value={item.value}>
                      {item.label}
                    </MenuItem>
                  ))}
                </Controller>
              </Grid>
              <Grid item xs={8} sm={4}>
                <Controller
                  as={
                    <TextField
                      fullWidth
                      required
                      // required={
                      //   watch('role', user?.role || 'user') === 'user'
                      // }
                      // hidden={watch('role', user?.role || 'user') !== 'user'}
                      className='input'
                      label='ชื่อ'
                      variant='outlined'
                    />
                  }
                  control={control}
                  name='firstName'
                  defaultValue={user?.fullName?.firstName || ''}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Controller
                  as={
                    <TextField
                      fullWidth
                      required
                      // required={
                      //   watch('role', user?.role || 'user') === 'user'
                      // }
                      // hidden={watch('role', user?.role || 'user') !== 'user'}
                      className='input'
                      label='นามสกุล'
                      variant='outlined'
                    />
                  }
                  control={control}
                  name='lastName'
                  defaultValue={user?.fullName?.lastName || ''}
                />
              </Grid>
            </Grid>

            <Controller
              as={
                <RadioGroup
                  row
                  // hidden={watch('role', user?.role || 'user') !== 'user'}
                />
              }
              control={control}
              name='code__type'
              defaultValue={user?.citizenship && user?.empCode ? '1' : user?.empCode ? '2' : '1'}
            >
              <FormControlLabel value='1' control={<Radio color='primary' />} label='รหัสบัตรประชาชน' />
              <FormControlLabel value='2' control={<Radio color='primary' />} label='รหัสพนักงาน' />
            </Controller>

            <Controller
              as={
                <TextField
                  // required={
                  //   watch('code__type', (user?.citizenship && user?.empCode) ? '1' : user?.empCode ? '2' : '1') === '1' &&
                  //   watch('role', user?.role || 'user') === 'user'
                  // }
                  // hidden={
                  //   watch('code__type', (user?.citizenship && user?.empCode) ? '1' : user?.empCode ? '2' : '1') !== '1' ||
                  //   watch('role', user?.role || 'user') !== 'user'
                  // }
                  required={
                    watch('code__type', user?.citizenship && user?.empCode ? '1' : user?.empCode ? '2' : '1') === '1'
                  }
                  hidden={
                    watch('code__type', user?.citizenship && user?.empCode ? '1' : user?.empCode ? '2' : '1') !== '1'
                  }
                  className='input'
                  label='รหัสบัตรประชาชน'
                  variant='outlined'
                  type='number'
                  autoComplete='off'
                />
              }
              control={control}
              name='citizenship'
              defaultValue={user?.citizenship || ''}
              rules={{ maxLength: 13, minLength: 13 }}
            />

            {(errors.citizenship?.type === 'maxLength' || errors.citizenship?.type === 'minLength') && (
              <Typography color='error'>กรุณาระบุรหัสบัตรประชาชน 13 หลักให้ถูกต้อง</Typography>
            )}

            <Controller
              as={
                <TextField
                  // required={
                  //   watch('code__type', (user?.citizenship && user?.empCode) ? '1' : user?.empCode ? '2' : '1') === '2' &&
                  //   watch('role', user?.role || 'user') === 'user'
                  // }
                  // hidden={
                  //   watch('code__type', (user?.citizenship && user?.empCode) ? '1' : user?.empCode ? '2' : '1') !== '2' ||
                  //   watch('role', user?.role || 'user') !== 'user'
                  // }
                  required={
                    watch('code__type', user?.citizenship && user?.empCode ? '1' : user?.empCode ? '2' : '1') === '2'
                  }
                  hidden={
                    watch('code__type', user?.citizenship && user?.empCode ? '1' : user?.empCode ? '2' : '1') !== '2'
                  }
                  className='input'
                  label='รหัสพนักงาน'
                  variant='outlined'
                  type='number'
                />
              }
              control={control}
              name='empCode'
              defaultValue={user?.empCode || ''}
            />

            <div className='alert-container'>{alert.show && <Alert severity={alert.type}>{alert.msg}</Alert>}</div>
            <div className='action'>
              <Button
                disabled={loading}
                className='cancel'
                variant='contained'
                onClick={() => history.push('/user-management')}
              >
                ยกเลิก
              </Button>
              <Button disabled={loading} type='submit' color='primary' variant='contained'>
                ยืนยัน
              </Button>
            </div>
          </form>
        </TabPanel>

        {/* SECTION: password tab */}
        <TabPanel value={tab} index={1}>
          <form className='form' onSubmit={handleSubmit(onUpdatePassword)}>
            <Controller
              as={
                <TextField
                  required
                  className='input'
                  label='รหัสผ่านใหม่'
                  variant='outlined'
                  disabled={loading}
                  type='password'
                />
              }
              control={control}
              name='newPassword'
              rules={{
                minLength: 6,
              }}
            />

            <Controller
              as={
                <TextField
                  required
                  className='input'
                  label='ยืนยันรหัสผ่าน'
                  variant='outlined'
                  disabled={loading}
                  type='password'
                />
              }
              control={control}
              name='confirmationPassword'
            />

            {watch('newPassword', '') &&
              watch('confirmationPassword', '') &&
              (watch('confirmationPassword', '') !== watch('newPassword', '') ||
                watch('newPassword', '')?.length < 6 ||
                watch('confirmationPassword', '')?.length < 6) && (
                <Typography color='error'>
                  การยืนยันรหัสผ่านไม่ตรงกับรหัสผ่าน หรือรหัสผ่านน้อยกว่า 6 ตัวอักษร
                </Typography>
              )}

            <ActionBtn />
          </form>
        </TabPanel>

        {loading && <LinearProgress />}
      </Form>
    </UserFormContainer>
  );
};

export default UserForm;
