import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import orm from '../../models/index';

// actions
import { appPropertySet } from '../../actions/AppProperty';
import { fetchPatientsVisitsDetailsSet } from '../../actions/PatientsVisitsDetails';

// react-bootstrap
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

// functions
import { ThaiDate } from '../../functions/FuncDateTimes';

// mui
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Grid from '@material-ui/core/Grid';

const session = orm.session();

class PatientData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pthn: '',
      pthnShow: '',
      patientData: [],
      drugAllergies: [],
    };
    this.getPatientData = this.getPatientData.bind(this);
  }

  getPatientData(ptId) {
    const ptData = this.props.appData.emrData.patients;

    session.TempData.delete({
      where(record) {
        return record.id !== '';
      },
    });

    if (ptData && ptData.data && ptData.data.drugAllergies && ptData.data.drugAllergies.length > 0) {
      for (let val of ptData.data.drugAllergies) {
        session.TempData.upsert({
          id: val.genericname,
          genericname: val.genericname,
          datas: val,
        });
      }
    }

    let drugAllergies = session.TempData.all()
      .filter(Temp2Data => Temp2Data.id !== '')
      .toRefArray();

    this.setState({ drugAllergies: drugAllergies });

    this.setState({ patientData: [ptData] });
  }

  componentDidUpdate() {
    if (this.props.ptId && (this.state.pthnShow <= '' || this.state.pthnShow !== this.props.hn)) {
      this.setState({ pthnShow: this.props.hn });
      this.getPatientData(this.props.ptId);
    }
  }

  render() {
    return (
      <div>
        {this.state.patientData.map((items, index) => (
          <Card key={index} className='patient-data patient-data-primary'>
            <CardContent className='patient-data patient-data-primary'>
              <Row className='margin-bottom-4px'>
                <Col lg={2}>
                  <Row>
                    <Col style={{ maxWidth: '145px' }}>
                      {items.data.hn && items.data.sex === '1' && (
                        <div
                          className='rounded img-thumbnail img-fluid pt-image'
                          style={{
                            backgroundImage: `url('/images/hospital-patient.png')`,
                          }}
                        />
                      )}
                      {items.data.hn && items.data.sex !== '1' && (
                        <div
                          className='rounded img-thumbnail img-fluid pt-image'
                          style={{
                            backgroundImage: `url('/images/hospital-patient-f.png')`,
                          }}
                        />
                      )}
                    </Col>
                    {this.props.width <= 991 && (
                      <Col style={{ paddingLeft: '0px' }}>
                        <h5>CID {items.data.hn && items.bid}</h5>
                        <h5>
                          ชื่อ-สกุล{' '}
                          {items.data.hn &&
                            items.data.fullname.title +
                              items.data.fullname.firstName +
                              '  ' +
                              items.data.fullname.lastName}
                        </h5>
                        <div>HN {items.data.hn && items.data.hn[0].hn}</div>
                        <div>
                          วันเกิด {items.data.hn && ThaiDate(items.data.DOB)} | อายุ {items.data.hn && items.data.age}{' '}
                          ปี
                        </div>
                      </Col>
                    )}
                  </Row>
                </Col>

                {this.props.width > 991 && (
                  <Col lg={10}>
                    <Row>
                      <Col lg={4}>
                        <h5>CID {items.data.hn && items.bid}</h5>
                      </Col>
                      <Col lg={6}>
                        <h5>
                          ชื่อ-สกุล{' '}
                          {items.data.hn &&
                            items.data.fullname.title +
                              items.data.fullname.firstName +
                              '  ' +
                              items.data.fullname.lastName}
                        </h5>
                      </Col>
                      <Col lg={2}>
                        <h5>เพศ {items.data.hn && items.data.sexName}</h5>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={4}>HN {items.data.hn && items.data.hn[0].hn}</Col>
                      <Col lg={4}>วันเกิด {items.data.hn && ThaiDate(items.data.DOB)}</Col>
                      <Col lg={4}>อายุ {items.data.hn && items.data.age} ปี</Col>
                    </Row>
                    <Row>
                      <Col lg={12}>
                        ที่อยู่ {items.data.hn && items.data.address.houseNumber + ' '}
                        {items.data.address.moopart && 'หมู ' + items.data.address.moopart + ' '}
                        {items.data.address.road && 'ถ. ' + items.data.address.road + ' '}
                        {items.data.hn && items.data.address.addressPart}
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={4}>เชื้อชาติ {items.data.hn && items.data.citizenship}</Col>
                      <Col lg={4}>สัญชาติ {items.data.hn && items.data.nationality}</Col>
                      <Col lg={4}>ศาสนา {items.data.hn && items.data.religion}</Col>
                    </Row>
                    <Row>
                      <Col lg={6}>เบอร์โทร {items.data.hn && items.data.contactInfo.mobileNumber}</Col>
                      <Col lg={6}>เบอร์โทรผู้ติดต่อ {items.data.hn && items.data.emergencyInfo.mobileNumber}</Col>
                    </Row>
                    <Row>
                      <Col lg={3}>ผู้ติดต่อ {items.data.hn && items.data.emergencyInfo.familyName}</Col>
                      <Col lg={9}>ที่อยู่ผู้ติดต่อ {items.data.hn && items.data.emergencyInfo.address.addressPart}</Col>
                    </Row>
                  </Col>
                )}
              </Row>
              <Row>
                <Col lg={12}>
                  <h4
                    className={
                      items.data.condition.ccPersistDisease !== '' && items.data.condition.ccPersistDisease
                        ? 'font-color-alert'
                        : ''
                    }
                  >
                    โรคประจำตัว {items.data.hn && items.data.condition.ccPersistDisease}
                  </h4>
                </Col>
              </Row>
              <Row>
                <Col lg={12}>
                  <h4 className={items.data.drugAllergies !== '' && items.data.drugAllergies ? 'font-color-alert' : ''}>
                    <div className='float-left' style={{ marginRight: '4px' }}>
                      แพ้ยา{' '}
                    </div>
                    {this.state.drugAllergies.map((items, index) => (
                      <div key={index} className='float-left' style={{ marginRight: '4px' }}>
                        {items.genericname} ,
                      </div>
                    ))}
                  </h4>
                </Col>
              </Row>
              <Row>
                {this.props.width <= 991 && (
                  <Col lg={12}>
                    <Row
                      style={{
                        marginLeft: '1px',
                        marginRight: '1px',
                        marginTop: '4px',
                      }}
                    >
                      <ExpansionPanel style={{ width: '100%' }} className='MuiAppBar-color-custom '>
                        <ExpansionPanelSummary
                          expandIcon={<ExpandMoreIcon className='app-text-color' />}
                          aria-controls='panel2a-content'
                          id='panel2a-header'
                        >
                          <Typography>ประวัติเพิ่มเติม</Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                          <Grid container spacing={1}>
                            <Grid item xs={12}>
                              ที่อยู่ {items.data.hn && items.data.address.houseNumber + ' '}
                              {items.data.address.moopart && 'หมู่ ' + items.data.address.moopart + ' '}
                              {items.data.address.road && 'ถ. ' + items.data.address.road + ' '}
                              {items.data.hn && items.data.address.addressPart}
                            </Grid>

                            <Grid item xs={4}>
                              เชื้อชาติ {items.data.hn && items.data.citizenship}
                            </Grid>
                            <Grid item xs={4}>
                              สัญชาติ {items.data.hn && items.data.nationality}
                            </Grid>
                            <Grid item xs={4}>
                              ศาสนา {items.data.hn && items.data.religion}
                            </Grid>

                            <Grid item xs={12}>
                              เบอร์โทร {items.data.hn && items.data.contactInfo.mobileNumber}
                            </Grid>

                            <Grid item xs={12}>
                              ผู้ติดต่อ {items.data.hn && items.data.emergencyInfo.familyName}
                            </Grid>
                            <Grid item xs={12}>
                              เบอร์โทรผู้ติดต่อ {items.data.hn && items.data.emergencyInfo.mobileNumber}
                            </Grid>
                            <Grid item xs={12}>
                              ที่อยู่ผู้ติดต่อ {items.data.hn && items.data.emergencyInfo.address.addressPart}
                            </Grid>
                          </Grid>
                        </ExpansionPanelDetails>
                      </ExpansionPanel>
                    </Row>
                  </Col>
                )}
              </Row>
            </CardContent>
          </Card>
        ))}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    appProperty: state.appProperty,
    appData: state.appData,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    appPropertySet: appPropertyData => dispatch(appPropertySet(appPropertyData)),
    fetchPatientsVisitsDetailsSet: dataRequest => dispatch(fetchPatientsVisitsDetailsSet(dataRequest)),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PatientData));
