import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { useIndexedDB } from 'react-indexed-db';
import orm from '../../models/index';

// actions
import { appPropertySet } from '../../actions/AppProperty';
import { fetchRefersAccept } from '../../actions/ReferAccept';
import { fetchRefersReject } from '../../actions/ReferReject';
import { fetchPatientsVisitsDetailsSet } from '../../actions/PatientsVisitsDetails';
import { fetchReferNotificationsRead } from '../../actions/ReferNotificationsRead';

// react-bootstrap
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

// functions
import { ThaiDateTime, ThaiDate } from '../../functions/FuncDateTimes';
import { shortHospName } from '../../functions/FuncPerjer';

// components
import ReferAcceptDialog from '../Utility/ReferAcceptDialog';
import ReferRejectDialog from '../Utility/ReferRejectDialog';

// mui
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import WarningIcon from '@material-ui/icons/Warning';

const session = orm.session();

function VisitsRefer({
  bid,
  appData,
  appProperty,
  fetchRefersAccept,
  fetchRefersReject,
  fetchPatientsVisitsDetailsSet,
  fetchReferNotificationsRead,
}) {
  const [visitData, setvisitData] = useState(null);
  const [bidData, setDataBid] = useState(false);
  const [anchorEl, setAnchorEl] = useState([]);
  const [RefreshRefer, setRefreshRefer] = useState(false);
  const [DialogAcceptOpen, setDialogAcceptOpen] = useState(false);
  const [DialogRejectOpen, setDialogRejectOpen] = useState(false);

  const [reason, setReason] = useState([]);

  const onReasonChange = (e, id) => {
    setReason({
      [id]: e.target.value,
    });
  };

  const setDataBidFc = bid => {
    setDataBid(bid);
  };

  const setRefreshReferFc = value => {
    setRefreshRefer(value);
  };

  const setvisitDataFc = bid => {
    const visits = appData.emrData.visits;

    session.Visits.delete({
      where(record) {
        return record.bid === bid;
      },
    });

    for (var val of visits) {
      session.Visits.upsert(val);
    }

    let visitsData = session.Visits.all()
      .filter(Visits => Visits.bid === bid)
      .toRefArray();

    for (let val2 of visitsData[0].referrals) {
      if (val2) {
        NotifyRead(val2.bid);
      }
    }

    setvisitData({ visitsData });
  };

  const NotifyRead = bid => {
    const { update, getByIndex } = useIndexedDB('notifications');

    if (bid) {
      let userId =
        appData.loginData.username.substring(4, 5) +
        appData.loginData.username.substring(2, 3) +
        appData.loginData.username.substring(1, 2) +
        appData.loginData.username.substring(5, 6) +
        appData.loginData.username.substring(3, 4) +
        appData.loginData.username.substring(6, 7) +
        appData.loginData.username.substring(7, 8) +
        appData.loginData.username.substring(10, 11) +
        appData.loginData.username.substring(9, 10) +
        appData.loginData.username.substring(8, 9) +
        appData.loginData.username.substring(0, 1);

      let keyId = bid + userId;

      getByIndex('keyId', keyId).then(NotifyFromDB => {
        if (NotifyFromDB) {
          update({
            id: NotifyFromDB.id,
            keyId: NotifyFromDB.keyId,
            key1: NotifyFromDB.key1,
            key2: NotifyFromDB.key2,
            notifyDateTime: NotifyFromDB.notifyDateTime,
            notifyType: NotifyFromDB.notifyType,
            notifyLink: NotifyFromDB.notifyLink,
            datas: NotifyFromDB.datas,
            notifyRead: true,
          });

          if (NotifyFromDB.notifyRead === false) {
            let dataRequest = {
              idToken: appData.idToken,
              bid: bid,
              hospCode: appData.loginData.hospCode,
            };

            fetchReferNotificationsRead(dataRequest);
          }
        }
      });
    }
  };

  const handleClick = (event, id) => {
    setAnchorEl({
      [id]: event.currentTarget,
    });
  };

  const handleClose = id => {
    setAnchorEl({
      [id]: null,
    });
  };

  const DialogAcceptOpenFc = id => {
    setDialogAcceptOpen({
      [id]: true,
    });

    handleClose(id);
  };

  const DialogAcceptCloseFc = id => {
    setDialogAcceptOpen({
      [id]: false,
    });
  };

  const DialogRejectOpenFc = id => {
    setDialogRejectOpen({
      [id]: true,
    });

    handleClose(id);
  };

  const DialogRejectCloseFc = id => {
    setDialogRejectOpen({
      [id]: false,
    });
  };

  const ReferAcceptSubmit = (id, bid, cid, visitBid) => {
    if (bid) {
      let dataRequest = {
        idToken: appData.idToken,
        bid: bid,
      };

      let res = fetchRefersAccept(dataRequest);

      res.then(
        function (v) {
          // ccccc
          RefreshPtData(cid, visitBid);
          DialogAcceptCloseFc(id);
        },
        function (e) {
          // TypeError: Throwing
          if (e.status !== 200) {
            alert('การบันทึกข้อมูลมีปัญหากรุณาลองใหม่อีกครั้ง !');
          } else {
          }
        }
      );
    }
  };

  const ReferRejectSubmit = (id, bid, cid, visitBid) => {
    if (!reason[id] || reason[id] === '' || reason[id] === undefined || reason[id] === null) {
      alert('คุณไม่ได้กรอกข้อมูลเหตุผลการปฏิเสธ !');
    } else {
      if (bid) {
        let txt = reason[id];

        let dataRequest = {
          idToken: appData.idToken,
          bid: bid,
          reason: txt,
        };

        let res = fetchRefersReject(dataRequest);

        res.then(
          function (v) {
            // ccccc
            RefreshPtData(cid, visitBid);
            DialogAcceptCloseFc(id);
          },
          function (e) {
            // TypeError: Throwing
            if (e.status !== 200) {
              alert('การบันทึกข้อมูลมีปัญหากรุณาลองใหม่อีกครั้ง !');
            } else {
            }
          }
        );
      }
    }
  };

  const RefreshPtData = (cid, visitBid) => {
    let dataRequest = {
      idToken: appData.idToken,
      cid: cid,
      hn: '',
      hospCode: appData.loginData.hospCode,
      limit: appProperty.visitLimit,
    };

    let res = fetchPatientsVisitsDetailsSet(dataRequest);
    res.then(
      function (v) {
        setRefreshReferFc(true);
      },
      function (e) {
        // TypeError: Throwing
        if (e.status !== 200) {
          alert('การค้นห้าข้อมูลมีปัญหากรุณาลองใหม่อีกครั้ง !');
        }
      }
    );
  };

  const expireDateInvalid = expireDateTime => {
    const expireDate = new Date(expireDateTime);
    return expireDate.getFullYear() <= 2000;
  };

  const expireDateDisplay = referItems => {
    const invalidDate = expireDateInvalid(referItems?.expireDateTime);
    let date = ThaiDate(referItems?.expireDateTime);
    if (invalidDate) {
      date = `${ThaiDate(dayjs(referItems?.referDateTime).add(6, 'months'))} (อัตโนมัติ)`;
      return (
        <>
          {date} <WarningIcon />
        </>
      );
    }
    return <>{date}</>;
  };

  useEffect(() => {
    if (bid !== bidData && !RefreshRefer) {
      setDataBidFc(bid);
      setvisitDataFc(bid);
    }
    if (RefreshRefer) {
      setRefreshReferFc(false);
      setTimeout(() => {
        setvisitDataFc(bid);
      }, 500);
    }
  });

  return (
    <div>
      {visitData && (
        <div>
          {visitData.visitsData.map((items, index) => (
            <div key={index}>
              {items.referrals.map((referItems, refIndex) => (
                <Card
                  variant='outlined'
                  className={`${
                    (referItems.status === '' || referItems.status === 'Pending') && 'margin-bottom-8px referrals-card'
                  } ${referItems.status === 'Rejected' && 'margin-bottom-8px referrals-card-rejected'} ${
                    referItems.status === 'Complete' && 'margin-bottom-8px referrals-card-complete'
                  }`}
                  key={refIndex}
                >
                  <CardHeader
                    className='card-header'
                    title={'Referral ID : ' + referItems.referoutId}
                    action={
                      <div>
                        {referItems.status === 'Pending' &&
                          referItems.toHospCode === appData.loginData.hospCode &&
                          appData?.permission?.actionRefer && (
                            <div>
                              <IconButton aria-label='settings' onClick={e => handleClick(e, refIndex)}>
                                <MoreVertIcon />
                              </IconButton>
                              <Menu
                                key={refIndex}
                                id={'refer-action-menu' + refIndex}
                                anchorEl={anchorEl[refIndex]}
                                keepMounted
                                open={Boolean(anchorEl[refIndex])}
                                onClose={() => handleClose(refIndex)}
                                className='refer-action-menu'
                              >
                                <MenuItem onClick={() => DialogAcceptOpenFc(refIndex)}>Accept (ยอมรับ Refer)</MenuItem>
                                <MenuItem onClick={() => DialogRejectOpenFc(refIndex)}>Reject (ปฏิเสธ Refer)</MenuItem>
                              </Menu>

                              <ReferAcceptDialog
                                open={Boolean(DialogAcceptOpen[refIndex])}
                                cid={referItems?.cid}
                                ptname={
                                  appData?.emrData?.patients?.data?.fullname?.title +
                                  ' ' +
                                  appData?.emrData?.patients?.data?.fullname?.firstName +
                                  ' ' +
                                  appData?.emrData?.patients?.data?.fullname?.lastName
                                }
                                hospName={referItems?.fromHospName}
                                onClose={() => DialogAcceptCloseFc(refIndex)}
                                onSubmit={() => ReferAcceptSubmit(refIndex, referItems.bid, items.cid, items.bid)}
                              />

                              <ReferRejectDialog
                                open={Boolean(DialogRejectOpen[refIndex])}
                                cid={referItems?.cid}
                                ptname={
                                  appData?.emrData?.patients?.data?.fullname?.title +
                                  ' ' +
                                  appData?.emrData?.patients?.data?.fullname?.firstName +
                                  ' ' +
                                  appData?.emrData?.patients?.data?.fullname?.lastName
                                }
                                hospName={referItems?.fromHospName}
                                reason={reason[refIndex]}
                                onClose={() => DialogRejectCloseFc(refIndex)}
                                onChange={e => onReasonChange(e, refIndex)}
                                onSubmit={() => ReferRejectSubmit(refIndex, referItems.bid, items.cid, items.bid)}
                              />
                            </div>
                          )}
                      </div>
                    }
                  />
                  <CardContent className='font-size-14px refer-text-custom'>
                    <Row>
                      <Col lg={2}>
                        <h6>สถานะ : {referItems.status}</h6>
                      </Col>
                      <Col lg={10}>
                        <h6>เหตุผล : {referItems.reason}</h6>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={6}>
                        <h6>วันที่ส่งตัว : {ThaiDateTime(referItems.referDateTime)}</h6>
                      </Col>
                      <Col lg={6}>
                        <h6
                          title={
                            expireDateInvalid(referItems?.expireDateTime)
                              ? `วันที่ถูกปรับอัตโนมัติจาก ${ThaiDate(referItems?.expireDateTime)}`
                              : ''
                          }
                        >
                          วันหมดอายุ :{' '}
                          {referItems.expireDateTime
                            ? expireDateDisplay(referItems)
                            : referItems?.referDateTime
                            ? `${ThaiDate(dayjs(referItems?.referDateTime).add(6, 'months'))} (อัตโนมัติ)`
                            : '-'}
                        </h6>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={6}>
                        <h6>
                          From hospital : {referItems.fromHospCode} {shortHospName(referItems.fromHospName)}
                        </h6>
                      </Col>
                      <Col lg={6}>
                        <h6>
                          To hospital : {referItems.toHospCode} {shortHospName(referItems.toHospName)}
                        </h6>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={12}>
                        <h6>สิทธิการรักษา : {referItems.pttypeName}</h6>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={12}>
                        <h6>Cause : -</h6>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={12}>
                        <h6>ICD10 : {referItems.icd + ' - ' + referItems.icdName}</h6>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={12}>
                        <h6>Pre Diagnosis : {referItems.preDiagnosis}</h6>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={12}>
                        <h6>เหตุผล : {referItems.refername}</h6>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={6}>
                        <h6>กรณี : {referItems.referoutTypeName}</h6>
                      </Col>
                      <Col lg={6}>
                        <h6>ประเภทอุบัติเหตุ : {referItems.referoutEmergencyTypeName}</h6>
                      </Col>
                    </Row>
                    {referItems.requestText !== '' && (
                      <Row>
                        <Col lg={12}>
                          <ExpansionPanel className='MuiAppBar-color-custom '>
                            <ExpansionPanelSummary
                              expandIcon={<ExpandMoreIcon className='app-text-color' />}
                              aria-controls='panel1a-content'
                              id='panel1a-header'
                            >
                              <Typography>REQUEST TEXT :</Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                              <Typography variant='caption'>
                                {referItems.requestText && (
                                  <div style={{ paddingLeft: '8px' }}>
                                    {referItems.requestText.split('\r').map((item2, index2) => (
                                      <div key={index2}>
                                        <h6>
                                          {index2 + 1}. {item2}
                                        </h6>
                                      </div>
                                    ))}
                                  </div>
                                )}
                              </Typography>
                            </ExpansionPanelDetails>
                          </ExpansionPanel>
                          <hr />
                        </Col>
                      </Row>
                    )}
                    {referItems.otherText !== '' && (
                      <Row>
                        <Col lg={12}>
                          <ExpansionPanel className='MuiAppBar-color-custom '>
                            <ExpansionPanelSummary
                              expandIcon={<ExpandMoreIcon className='app-text-color' />}
                              aria-controls='panel1a-content'
                              id='panel1a-header'
                            >
                              <Typography>OTHER TEXT :</Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                              <Typography variant='caption'>
                                {referItems.otherText && (
                                  <div style={{ paddingLeft: '8px' }}>
                                    {referItems.otherText.split('\r').map((item2, index2) => (
                                      <div key={index2}>
                                        <h6>
                                          {index2 + 1}. {item2}
                                        </h6>
                                      </div>
                                    ))}
                                  </div>
                                )}
                              </Typography>
                            </ExpansionPanelDetails>
                          </ExpansionPanel>
                          <hr />
                        </Col>
                      </Row>
                    )}
                    {referItems.treatmentText !== '' && (
                      <Row>
                        <Col lg={12}>
                          <ExpansionPanel className='MuiAppBar-color-custom '>
                            <ExpansionPanelSummary
                              expandIcon={<ExpandMoreIcon className='app-text-color' />}
                              aria-controls='panel1a-content'
                              id='panel1a-header'
                            >
                              <Typography>TREATMENT :</Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                              <Typography variant='caption'>
                                {referItems.treatmentText && (
                                  <div style={{ paddingLeft: '8px' }}>
                                    {referItems.treatmentText.split('\r').map((item2, index2) => (
                                      <div key={index2}>
                                        <h6>
                                          {index2 + 1}. {item2}
                                        </h6>
                                      </div>
                                    ))}
                                  </div>
                                )}
                              </Typography>
                            </ExpansionPanelDetails>
                          </ExpansionPanel>
                          <hr />
                        </Col>
                      </Row>
                    )}
                    {referItems.pmh !== '' && (
                      <Row>
                        <Col lg={12}>
                          <ExpansionPanel className='MuiAppBar-color-custom '>
                            <ExpansionPanelSummary
                              expandIcon={<ExpandMoreIcon className='app-text-color' />}
                              aria-controls='panel1a-content'
                              id='panel1a-header'
                            >
                              <Typography>Past medical history :</Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                              <Typography variant='caption'>
                                {referItems.pmh && (
                                  <div style={{ paddingLeft: '8px' }}>
                                    {referItems.pmh.split('\r').map((item2, index2) => (
                                      <div key={index2}>
                                        <h6>
                                          {index2 + 1}. {item2}
                                        </h6>
                                      </div>
                                    ))}
                                  </div>
                                )}
                              </Typography>
                            </ExpansionPanelDetails>
                          </ExpansionPanel>
                          <hr />
                        </Col>
                      </Row>
                    )}
                    {referItems.hpi !== '' && (
                      <Row>
                        <Col lg={12}>
                          <ExpansionPanel className='MuiAppBar-color-custom '>
                            <ExpansionPanelSummary
                              expandIcon={<ExpandMoreIcon className='app-text-color' />}
                              aria-controls='panel1a-content'
                              id='panel1a-header'
                            >
                              <Typography>History of present illness :</Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                              <Typography variant='caption'>
                                {referItems.hpi && (
                                  <div style={{ paddingLeft: '8px' }}>
                                    {referItems.hpi.split('\r').map((item2, index2) => (
                                      <div key={index2}>
                                        <h6>
                                          {index2 + 1}. {item2}
                                        </h6>
                                      </div>
                                    ))}
                                  </div>
                                )}
                              </Typography>
                            </ExpansionPanelDetails>
                          </ExpansionPanel>
                          <hr />
                        </Col>
                      </Row>
                    )}
                  </CardContent>
                </Card>
              ))}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

function mapStateToProps(state) {
  return {
    appProperty: state.appProperty,
    appData: state.appData,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    appPropertySet: appPropertyData => dispatch(appPropertySet(appPropertyData)),
    fetchPatientsVisitsDetailsSet: dataRequest => dispatch(fetchPatientsVisitsDetailsSet(dataRequest)),
    fetchRefersAccept: dataRequest => dispatch(fetchRefersAccept(dataRequest)),
    fetchRefersReject: dataRequest => dispatch(fetchRefersReject(dataRequest)),
    fetchReferNotificationsRead: dataRequest => dispatch(fetchReferNotificationsRead(dataRequest)),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(VisitsRefer));
