import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import dayjs from 'dayjs';

// functions
import { getBillingStatusConfig, formatNum } from '../../../functions/FuncPerjer';
import { ThaiDate } from '../../../functions/FuncDateTimes';

// styles
import { Container, TableRow, TableCell, Status } from '../styles';

// actions
import { fetchBillingItemsByPatient } from '../../../actions/BillingsCharge';

// constants
import { DEST_ACCEPTED, SOURCE_ACCEPTED, PROCESSING, REJECTED, EDITED } from '../../../constants/Billings/billingStatus';

// mui
import EventNoteIcon from '@material-ui/icons/EventNote';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import Pagination from '@material-ui/lab/Pagination';

const ChargePatients = props => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [page, setPage] = useState(1);

  const { appData } = useSelector(state => state);
  const { idToken, hospCode, FetchingStatus, billlingPatients, loginData } = appData;

  const { category, monthIndex, selectedHosp } = props.match.params;
  const startDate = dayjs([history.location.state?.year, Number(monthIndex), 1])
    .startOf('month')
    .toISOString();
  const endDate = dayjs([history.location.state?.year, Number(monthIndex), 1])
    .endOf('month')
    .toISOString();
  const { categoryName } = new URLSearchParams(history.location.search)?.get('categoryName');
  const asProvider = history.location.pathname.indexOf('/to') !== -1 ? true : false;

  useEffect(() => {
    const pageCache = localStorage.getItem('page');
    Number(pageCache) && setPage(Number(pageCache));
  }, []);

  useEffect(() => {
    if (loginData?.role?.toUpperCase() === 'SUPERADMIN') {
      handleFetchBillingItemByPatient({
        idToken,
        hospCode: asProvider ? history.location?.state?.provider?.hospCode : history.location?.state?.payer?.hospCode,
        hospmain: asProvider ? history.location?.state?.payer?.hospCode : history.location?.state?.provider?.hospCode,
        category,
        categoryName: new URLSearchParams(history.location.search)?.get('categoryName'),
        startDate,
        endDate,
        page,
      });
    } else {
      handleFetchBillingItemByPatient({
        idToken,
        hospCode: asProvider ? hospCode : selectedHosp,
        hospmain: asProvider ? selectedHosp : hospCode,
        category,
        categoryName: new URLSearchParams(history.location.search)?.get('categoryName'),
        startDate,
        endDate,
        page,
      });
    }
  }, [startDate, endDate, category, hospCode, idToken, selectedHosp, page, asProvider, loginData]);

  const handleFetchBillingItemByPatient = ({
    idToken,
    hospCode,
    hospmain,
    category,
    categoryName,
    startDate,
    endDate,
    page,
  }) => {
    dispatch(
      fetchBillingItemsByPatient({
        idToken,
        hospCode,
        hospmain,
        category,
        categoryName,
        startDate,
        endDate,
        page,
      })
    );
  };

  const getPatientBilling = ({ row }) => {
    history.push({
      pathname: `patients/${row?.cid}`,
      search: `?&categoryName=${new URLSearchParams(history.location.search)?.get('categoryName')}&year=${
        history.location.state?.year
      }`,
      state: {
        vn: row.vn,
        categoryId: history?.location?.state?.categoryId,
        provider: history.location?.state?.provider,
        payer: history.location?.state?.payer,
      },
    });
  };

  return (
    <>
      <Container>
        <div className='header'>
          <EventNoteIcon className='header-icon' />
          <h5 className='title'>
            {asProvider ? 'เรียกเก็บ' : 'ตามจ่าย'} -{' '}
            {ThaiDate(dayjs([history.location.state?.year, monthIndex, 1])).substr(2, 10)}
          </h5>
        </div>
        {!FetchingStatus && billlingPatients && (
          <div className='table-head'>
            <h4>
              <b>{categoryName}</b>
            </h4>
          </div>
        )}
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align='center'>วันที่</TableCell>
              <TableCell align='right'>CID</TableCell>
              <TableCell align='center'>ชื่อ</TableCell>
              <TableCell align='right'>ยอดเงินตามจริง (฿) </TableCell>
              <TableCell align='right'>ยอดที่คำนวณ (฿)</TableCell>
              <TableCell align='right'>ยอดที่เรียกเก็บ (฿)</TableCell>
              <TableCell align='right'>สถานะ</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!FetchingStatus && billlingPatients && billlingPatients?.docs?.length > 0 ? (
              billlingPatients.docs
                ?.filter(row =>
                  asProvider
                    ? row
                    : history.location.state?.summaryStatus === PROCESSING
                    ? row.billing?.status === DEST_ACCEPTED ||
                      row.billing?.status === SOURCE_ACCEPTED ||
                      row.billing?.status === REJECTED ||
                      row.billing?.status === EDITED
                    : row
                )
                ?.map(row => (
                  <TableRow key={row?._id} onClick={() => getPatientBilling({ row })}>
                    <TableCell align='center' component='th' scope='row'>
                      {new Date(row?.visitDateTime?.substr(0,10)).toLocaleDateString('th-TH', {
                        day: 'numeric',
                        month: 'short',
                        year: 'numeric',
                      })}
                    </TableCell>
                    <TableCell align='right'>{row?.cid}</TableCell>
                    <TableCell align='center'>
                      {row?.patient?.data?.fullname?.firstName} {row?.patient?.data?.fullname?.lastName}
                    </TableCell>
                    <TableCell align='right'>{formatNum(row?.billing?.actualTotal)}</TableCell>
                    <TableCell align='right'>{formatNum(row?.billing?.calcTotal)}</TableCell>
                    <TableCell align='right'>{formatNum(row?.billing?.finalTotal)}</TableCell>
                    <TableCell align='right'>
                      <Status color={getBillingStatusConfig(row?.billing?.status)?.color}>
                        {getBillingStatusConfig(row?.billing?.status)?.text}
                      </Status>
                    </TableCell>
                    <TableCell align='right'></TableCell>
                  </TableRow>
                ))
            ) : (
              <TableRow>
                <TableCell colSpan={7} align='center' component='th' scope='row'>
                  ไม่พบข้อมูล
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        <div className='pagination-table'>
          <Pagination
            page={page}
            count={billlingPatients?.totalPages}
            onChange={(e, value) => (setPage(value), localStorage.setItem('page', value))}
          />
        </div>
      </Container>
    </>
  );
};

export default ChargePatients;
