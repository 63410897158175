import styled from 'styled-components';
import MTTableRow from '@material-ui/core/TableRow';
import MTTableCell from '@material-ui/core/TableCell';
import { withStyles } from '@material-ui/core/styles';

export const TableCell = withStyles(theme => ({
  head: {
    backgroundColor: theme.palette.common.white,
  },
}))(MTTableCell);

export const TableRow = withStyles(theme => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(MTTableRow);

export const Container = styled.div`
  text-align: initial;
  .recalculateBilling {
    margin-right: 8px;
  }
  .export {
    margin-right: 8px;
  }
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .edit-cate {
    margin-right: 5px;
  }
`;
