export function ThaiDate(d) {
  if (d !== '' && d !== undefined && d !== null && d !== 'undefined') {
    let tnow = new Date(d);

    if (tnow.toString() !== 'Invalid Date') {
      // let thday = new Array('อาทิตย์', 'จันทร์', 'อังคาร', 'พุธ', 'พฤหัส', 'ศุกร์', 'เสาร์')
      let thmonth = ['ม.ค', 'ก.พ', 'มี.ค', 'เม.ย', 'พ.ค', 'มิ.ย', 'ก.ค', 'ส.ค', 'ก.ย', 'ต.ค', 'พ.ย', 'ธ.ค'];

      return tnow.getDate() + ' ' + thmonth[tnow.getMonth()] + ' ' + (0 + tnow.getFullYear() + 543);
    } else {
      return '';
    }
  } else {
    return '';
  }
}

export function ThaiDateTime(d) {
  if (d !== '' && d !== undefined && d !== null && d !== 'undefined') {
    let tnow = new Date(d.replace(' ', 'T'));

    if (tnow.toString() !== 'Invalid Date') {
      // let thday = new Array('อาทิตย์', 'จันทร์', 'อังคาร', 'พุธ', 'พฤหัส', 'ศุกร์', 'เสาร์')
      let thmonth = ['ม.ค', 'ก.พ', 'มี.ค', 'เม.ย', 'พ.ค', 'มิ.ย', 'ก.ค', 'ส.ค', 'ก.ย', 'ต.ค', 'พ.ย', 'ธ.ค'];

      let Hours = '';
      let Minutes = '';
      let Seconds = '';
      if (tnow.getHours().toString().length === 1) {
        Hours = '0' + tnow.getHours().toString();
      } else {
        Hours = tnow.getHours().toString();
      }

      if (tnow.getMinutes().toString().length === 1) {
        Minutes = '0' + tnow.getMinutes().toString();
      } else {
        Minutes = tnow.getMinutes().toString();
      }

      if (tnow.getSeconds().toString().length === 1) {
        Seconds = '0' + tnow.getSeconds().toString();
      } else {
        Seconds = tnow.getSeconds().toString();
      }

      return (
        tnow.getDate() +
        ' ' +
        thmonth[tnow.getMonth()] +
        ' ' +
        (0 + tnow.getFullYear() + 543) +
        ' ' +
        Hours +
        ':' +
        Minutes +
        ':' +
        Seconds
      );
    } else {
      return '';
    }
  } else {
    return '';
  }
}

export function ThaiDateTimeNonSec(d) {
  if (d !== '' && d !== undefined && d !== null && d !== 'undefined') {
    let tnow = new Date(d.replace(' ', 'T'));
    if (tnow.toString() !== 'Invalid Date') {
      // let thday = new Array('อาทิตย์', 'จันทร์', 'อังคาร', 'พุธ', 'พฤหัส', 'ศุกร์', 'เสาร์')
      let thmonth = ['ม.ค', 'ก.พ', 'มี.ค', 'เม.ย', 'พ.ค', 'มิ.ย', 'ก.ค', 'ส.ค', 'ก.ย', 'ต.ค', 'พ.ย', 'ธ.ค'];

      let Hours = '';
      let Minutes = '';
      if (tnow.getHours().toString().length === 1) {
        Hours = '0' + tnow.getHours().toString();
      } else {
        Hours = tnow.getHours().toString();
      }

      if (tnow.getMinutes().toString().length === 1) {
        Minutes = '0' + tnow.getMinutes().toString();
      } else {
        Minutes = tnow.getMinutes().toString();
      }

      return (
        tnow.getDate() +
        ' ' +
        thmonth[tnow.getMonth()] +
        ' ' +
        (0 + tnow.getFullYear() + 543) +
        ' ' +
        Hours +
        ':' +
        Minutes
      );
    } else {
      return '';
    }
  } else {
    return '';
  }
}

export function DateNow() {
  let dnow = new Date();

  let DateNow = '';
  let Days = '';
  let Months = '';

  if (dnow.getDate().toString().length === 1) {
    Days = '0' + dnow.getDate().toString();
  } else {
    Days = dnow.getDate().toString();
  }

  if ((dnow.getMonth() + 1).toString().length === 1) {
    Months = '0' + (dnow.getMonth() + 1).toString();
  } else {
    Months = (dnow.getMonth() + 1).toString();
  }

  DateNow = dnow.getFullYear() + '-' + Months + '-' + Days;

  return DateNow;
}

export function TimeNow() {
  let tnow = new Date();
  let Hours = '';
  let Minutes = '';
  let Seconds = '';
  if (tnow.getHours().toString().length === 1) {
    Hours = '0' + tnow.getHours().toString();
  } else {
    Hours = tnow.getHours().toString();
  }

  if (tnow.getMinutes().toString().length === 1) {
    Minutes = '0' + tnow.getMinutes().toString();
  } else {
    Minutes = tnow.getMinutes().toString();
  }

  if (tnow.getSeconds().toString().length === 1) {
    Seconds = '0' + tnow.getSeconds().toString();
  } else {
    Seconds = tnow.getSeconds().toString();
  }

  return Hours + ':' + Minutes + ':' + Seconds;
}

export function DateTimeNow() {
  let tnow = new Date();

  let month = tnow.getMonth() + 1;

  let monthst = '';

  let datest = '';

  if (month.toString().length === 1) {
    monthst = '0' + month.toString();
  } else {
    monthst = month.toString();
  }

  if (tnow.getDate().toString().length === 1) {
    datest = '0' + tnow.getDate().toString();
  } else {
    datest = tnow.getDate().toString();
  }

  let DateNow = tnow.getFullYear() + '-' + monthst + '-' + datest;
  let Hours = '';
  let Minutes = '';
  let Seconds = '';

  if (tnow.getHours().toString().length === 1) {
    Hours = '0' + tnow.getHours().toString();
  } else {
    Hours = tnow.getHours().toString();
  }

  if (tnow.getMinutes().toString().length === 1) {
    Minutes = '0' + tnow.getMinutes().toString();
  } else {
    Minutes = tnow.getMinutes().toString();
  }

  if (tnow.getSeconds().toString().length === 1) {
    Seconds = '0' + tnow.getSeconds().toString();
  } else {
    Seconds = tnow.getSeconds().toString();
  }

  return DateNow + ' ' + Hours + ':' + Minutes + ':' + Seconds;
}

export function StrToDate(d) {
  if (d !== '' && d !== undefined && d !== null && d !== 'undefined') {
    let tnow = new Date(d.replace(' ', 'T'));
    return tnow;
  } else {
    return '';
  }
}

export function DateTimeToStrDate(d) {
  let tnow = new Date(d);
  let DateNow = '';
  let Days = '';
  let Months = '';
  if (tnow.getDate().toString().length === 1) {
    Days = '0' + tnow.getDate().toString();
  } else {
    Days = tnow.getDate().toString();
  }

  if ((tnow.getMonth() + 1).toString().length === 1) {
    Months = '0' + (tnow.getMonth() + 1).toString();
  } else {
    Months = (tnow.getMonth() + 1).toString();
  }

  DateNow = tnow.getFullYear() + '-' + Months + '-' + Days;

  return DateNow;
}

export function DateTimeToStrDatetime(d) {
  let tnow = new Date(d);
  let DateNow = '';
  let Days = '';
  let Months = '';
  if (tnow.getDate().toString().length === 1) {
    Days = '0' + tnow.getDate().toString();
  } else {
    Days = tnow.getDate().toString();
  }

  if ((tnow.getMonth() + 1).toString().length === 1) {
    Months = '0' + (tnow.getMonth() + 1).toString();
  } else {
    Months = (tnow.getMonth() + 1).toString();
  }

  DateNow = tnow.getFullYear() + '-' + Months + '-' + Days;

  let Hours = '';
  let Minutes = '';
  let Seconds = '';
  if (tnow.getHours().toString().length === 1) {
    Hours = '0' + tnow.getHours().toString();
  } else {
    Hours = tnow.getHours().toString();
  }

  if (tnow.getMinutes().toString().length === 1) {
    Minutes = '0' + tnow.getMinutes().toString();
  } else {
    Minutes = tnow.getMinutes().toString();
  }

  if (tnow.getSeconds().toString().length === 1) {
    Seconds = '0' + tnow.getSeconds().toString();
  } else {
    Seconds = tnow.getSeconds().toString();
  }

  return DateNow + ' ' + Hours + ':' + Minutes + ':' + Seconds;
}

export function DateTimeToStrTime(d) {
  let tnow = new Date(d);
  let Hours = '';
  let Minutes = '';
  let Seconds = '';
  if (tnow.getHours().toString().length === 1) {
    Hours = '0' + tnow.getHours().toString();
  } else {
    Hours = tnow.getHours().toString();
  }

  if (tnow.getMinutes().toString().length === 1) {
    Minutes = '0' + tnow.getMinutes().toString();
  } else {
    Minutes = tnow.getMinutes().toString();
  }

  if (tnow.getSeconds().toString().length === 1) {
    Seconds = '0' + tnow.getSeconds().toString();
  } else {
    Seconds = tnow.getSeconds().toString();
  }

  return Hours + ':' + Minutes + ':' + Seconds;
}

export function DateTimeToIntDateTime(d) {
  let tnow = new Date(d);
  let DateNow = '';
  let Days = '';
  let Months = '';
  let Hours = '';
  let Minutes = '';
  let Seconds = '';

  if (tnow.getDate().toString().length === 1) {
    Days = '0' + tnow.getDate().toString();
  } else {
    Days = tnow.getDate().toString();
  }

  if ((tnow.getMonth() + 1).toString().length === 1) {
    Months = '0' + (tnow.getMonth() + 1).toString();
  } else {
    Months = (tnow.getMonth() + 1).toString();
  }

  DateNow = tnow.getFullYear() + '' + Months + '' + Days;

  if (tnow.getHours().toString().length === 1) {
    Hours = '0' + tnow.getHours().toString();
  } else {
    Hours = tnow.getHours().toString();
  }

  if (tnow.getMinutes().toString().length === 1) {
    Minutes = '0' + tnow.getMinutes().toString();
  } else {
    Minutes = tnow.getMinutes().toString();
  }

  if (tnow.getSeconds().toString().length === 1) {
    Seconds = '0' + tnow.getSeconds().toString();
  } else {
    Seconds = tnow.getSeconds().toString();
  }

  return DateNow + '' + Hours + '' + Minutes + '' + Seconds;
}

export function DateTimeToStrTimeNoneSec(d) {
  let tnow = new Date(d);
  let Hours = '';
  let Minutes = '';
  if (tnow.getHours().toString().length === 1) {
    Hours = '0' + tnow.getHours().toString();
  } else {
    Hours = tnow.getHours().toString();
  }

  if (tnow.getMinutes().toString().length === 1) {
    Minutes = '0' + tnow.getMinutes().toString();
  } else {
    Minutes = tnow.getMinutes().toString();
  }

  return Hours + ':' + Minutes;
}

export function DateTimeId(d) {
  if (d !== '' && d !== undefined && d !== null && d !== 'undefined') {
    let tnow = new Date(d.replace(' ', 'T'));

    let Years = String(tnow.getFullYear());
    let Months = String(tnow.getMonth() + 1);
    let Dates = String(tnow.getDate());

    if (Months.length === 1) {
      Months = '0' + Months;
    }
    if (Dates.length === 1) {
      Dates = '0' + Dates;
    }

    let DateNow = Years + Months + Dates;

    let Hours = '';
    let Minutes = '';
    let Seconds = '';
    if (tnow.getHours().toString().length === 1) {
      Hours = '0' + tnow.getHours().toString();
    } else {
      Hours = tnow.getHours().toString();
    }

    if (tnow.getMinutes().toString().length === 1) {
      Minutes = '0' + tnow.getMinutes().toString();
    } else {
      Minutes = tnow.getMinutes().toString();
    }

    if (tnow.getSeconds().toString().length === 1) {
      Seconds = '0' + tnow.getSeconds().toString();
    } else {
      Seconds = tnow.getSeconds().toString();
    }

    return DateNow + '' + Hours + '' + Minutes + '' + Seconds;
  } else {
    return '';
  }
}

export function ThiaDateToDate(d) {
  if (d !== null && d !== '') {
    let Yt = d.substr(0, 4) - 543;
    let Mt = d.substr(4, 2);
    let Dt = d.substr(6, 2);
    return Yt + '-' + Mt + '-' + Dt;
  } else {
    return '';
  }
}

export function StrToDateTime(d) {
  if (d !== null && d !== '') {
    let tnow = new Date(d.replace(' ', 'T'));
    return tnow;
  } else {
    return new Date();
  }
}

export function addDays(date, days) {
  const copy = new Date(Number(date));
  copy.setDate(date.getDate() + days);
  return copy;
}

export function delDays(date, days) {
  const copy = new Date(Number(date));
  copy.setDate(date.getDate() - days);
  return copy;
}

export function diffMinutes(dt22, dt11) {
  if (
    dt11 !== '' &&
    dt11 !== undefined &&
    dt11 !== null &&
    dt11 !== 'undefined' &&
    dt22 !== '' &&
    dt22 !== undefined &&
    dt22 !== null &&
    dt22 !== 'undefined'
  ) {
    let dt1 = new Date(dt11.replace(' ', 'T'));
    let dt2 = new Date(dt22.replace(' ', 'T'));

    var diff = (dt2.getTime() - dt1.getTime()) / 1000;
    diff /= 60;
    return Math.abs(Math.round(diff));
  } else {
    return '';
  }
}
