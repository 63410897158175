import React, { useState } from 'react';

// constants
import { REFERIN } from '../../../constants/Refers/referType';

// mui
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import ApartmentIcon from '@material-ui/icons/Apartment';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    marginBottom: '10px',
  },
}));

export default function ReferTab(props) {
  const classes = useStyles();
  const [value, setValue] = useState(0);

  const handleChange = newValue => {
    props.onChange(newValue);
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <AppBar position='static' color='default'>
        <Tabs value={value} variant='scrollable' scrollButtons='on' indicatorColor='primary' textColor='primary'>
          {props.referDatas?.length > 0 &&
            props.referDatas.map((item, idx) => (
              <>
                <Tab
                  label={props.referType === REFERIN ? item[0]?.toHospName : item[0]?.fromHospName}
                  icon={<ApartmentIcon />}
                  onChange={e => handleChange(idx)}
                />
              </>
            ))}
        </Tabs>
      </AppBar>
    </div>
  );
}
