import { APPDATA } from '../constants/appData';
import getLogin from '../api/Login';

// Login
export function fetchLogin(username, password) {
  // console.log('mmmmmm',displayShow)
  return {
    type: APPDATA,
    payload: getLogin(username, password),
  };
}
