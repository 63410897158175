import React, { Component } from 'react';

// assets
import logo from '../../images/ever_logo.8c71ea29.png';
import logoDark from '../../images/logo-img@3x.7745c800.png';

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dark: false,
      theme: 'light',
    };
    this.changTheme = this.changTheme.bind(this);
  }

  componentDidMount() {
    localStorage.removeItem('billing-charge');
  }

  changTheme(tt) {
    if (tt === 'light') {
      this.setState({ dark: false });
      this.setState({ theme: 'light' });
    } else {
      this.setState({ dark: true });
      this.setState({ theme: 'dark' });
    }
  }

  render() {
    if (this.state.theme !== localStorage.getItem('theme')) {
      setTimeout(() => {
        this.changTheme(localStorage.getItem('theme'));
      }, 100);
    }

    return (
      <div>
        <header>
          {localStorage.getItem('theme') === 'dark' ? (
            <img src={logo} className='App-logo' alt='logo' />
          ) : (
            <img src={logoDark} className='App-logo' alt='logo' />
          )}

          <div>
            <h2 className='font-family-custom text-size-5vmin'>EverHIE</h2>

            <h5 className='text-size-3vmin'>(Hospital Information Exchange)</h5>

            <h5 className='text-size-3vmin'>ระบบแลกเปลี่ยนข้อมูลสุขภาพ</h5>
          </div>
        </header>
      </div>
    );
  }
}

export default Home;
