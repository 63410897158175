import React, { Component } from 'react';
import { connect } from 'react-redux';
import ApexCharts from 'react-apexcharts';
import orm from '../../models/index';

// actions
import { appPropertySet } from '../../actions/AppProperty';
import { fetchRefers } from '../../actions/ReferDatas';

// functions
import { ThaiDate } from '../../functions/FuncDateTimes';
import { shortHospName } from '../../functions/FuncPerjer';
import { setReportHeight } from '../../functions/Report';

// mui
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

const session = orm.session();

class ReferInPercentChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hospCodeData: [],
      ReferDatas: [],
      StackedBarsOptions: {},
      StackedBarsSeries: [],
      StackedBars100options: {},
      StackedBars100series: [],
    };
    this.ReferProcess = this.ReferProcess.bind(this);
  }

  ReferProcess() {
    const referDb = this.state.ReferDatas;

    session.ReferInDatas.delete({
      where(record) {
        return record._id !== '';
      },
    });

    session.ReferHosp.delete({
      where(record) {
        return record.id !== '';
      },
    });

    for (var val of referDb) {
      session.ReferInDatas.upsert(val);

      session.ReferHosp.upsert({
        id: val.fromHospCode,
        hospName: val.fromHospName ? val.fromHospCode + ' ' + shortHospName(val.fromHospName) : val.fromHospCode,
      });
    }

    let hospCodeData2 = session.ReferHosp.all()
      .filter(ReferHosp => ReferHosp.id !== '')
      .orderBy(ReferHosp => ReferHosp.hospName, 'asc')
      .toRefArray();

    this.setState({ hospCodeData: hospCodeData2 });

    let hospCategories = [];
    let referInCount = [];
    let ReferInAllData = [];
    let ReferInPendingData = [];
    let ReferInAcceptData = [];
    let ReferInRejectData = [];

    for (let hosp of hospCodeData2) {
      hospCategories.push(hosp.hospName);

      let referInAll = session.ReferInDatas.all()
        .filter(ReferInDatas => ReferInDatas.fromHospCode === hosp.id)
        .toRefArray();

      ReferInAllData.push(referInAll.length);

      let referInPending = session.ReferInDatas.all()
        .filter(ReferInDatas => ReferInDatas.fromHospCode === hosp.id)
        .filter(ReferInDatas => ReferInDatas.status === 'Pending')
        .toRefArray();

      ReferInPendingData.push(referInPending.length);

      let referInAccept = session.ReferInDatas.all()
        .filter(ReferInDatas => ReferInDatas.fromHospCode === hosp.id)
        .filter(ReferInDatas => ReferInDatas.status === 'Complete')
        .toRefArray();

      ReferInAcceptData.push(referInAccept.length);

      let referInReject = session.ReferInDatas.all()
        .filter(ReferInDatas => ReferInDatas.fromHospCode === hosp.id)
        .filter(ReferInDatas => ReferInDatas.status === 'Rejected')
        .toRefArray();

      ReferInRejectData.push(referInReject.length);
    }

    /*
		referInCount.push({
			name: 'Refer In',
			data: ReferInAllData
		})
		*/

    referInCount.push({
      name: 'Pending',
      data: ReferInPendingData,
    });

    referInCount.push({
      name: 'Accept',
      data: ReferInAcceptData,
    });

    referInCount.push({
      name: 'Rejected',
      data: ReferInRejectData,
    });

    let options = {
      chart: {
        type: 'bar',
        height: this.state.hospCodeData.length * 40,
        stacked: true,
        stackType: '100%',
      },
      plotOptions: {
        bar: {
          horizontal: true,
        },
      },
      stroke: {
        width: 1,
        colors: ['#fff'],
      },
      title: {
        text: 'เปอร์เซ็น Refer In | ' + ThaiDate(this.props.StartDate) + ' - ' + ThaiDate(this.props.EndDate),
      },
      xaxis: {
        categories: hospCategories,
        labels: {
          formatter: function (val) {
            return val + ' %';
          },
        },
      },
      yaxis: {
        labels: {
          style: {
            fontSize: '14px',
          },
          align: 'left',
          offsetX: 20,
          paddingRight: 50,
        },
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return val + ' ครั้ง';
          },
        },
        style: {
          fontSize: '14px',
        },
      },
      fill: {
        opacity: 1,
      },
      legend: {
        position: 'top',
        horizontalAlign: 'left',
        offsetX: 40,
      },
    };

    this.setState({ StackedBars100options: options });
    this.setState({ StackedBars100series: referInCount });
  }

  componentDidMount() {
    if (this.state.ReferDatas !== this.props.referInData) {
      this.setState({ ReferDatas: this.props.referInData });
      setTimeout(() => {
        this.ReferProcess();
      }, 500);
    }
  }

  componentDidUpdate() {
    if (this.state.ReferDatas !== this.props.referInData) {
      this.setState({ ReferDatas: this.props.referInData });

      setTimeout(() => {
        this.ReferProcess();
      }, 500);
    }
  }

  render() {
    return (
      <div>
        {this.state.StackedBars100series.length > 0 && (
          <Card className='card-refer'>
            <CardContent>
              <div id='chart2' className='refer-chart-box'>
                <ApexCharts
                  options={this.state.StackedBars100options}
                  series={this.state.StackedBars100series}
                  type='bar'
                  height={setReportHeight(this.state?.hospCodeData?.length)}
                />
              </div>
            </CardContent>
          </Card>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    appProperty: state.appProperty,
    appData: state.appData,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    appPropertySet: appPropertyData => dispatch(appPropertySet(appPropertyData)),
    fetchRefers: dataRequest => dispatch(fetchRefers(dataRequest)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ReferInPercentChart);
