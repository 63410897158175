import { APPDATA } from '../../constants/appData';

// apis
import RecalculateBilling from '../../api/Billings/RecalculateBilling';

export function recalculateBilling({ idToken, monthIndex, hospCode }) {
  return {
    type: APPDATA,
    payload: RecalculateBilling({ idToken, monthIndex, hospCode }),
  };
}
