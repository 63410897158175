import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import orm from '../../models/index';

// actions
import { appPropertySet } from '../../actions/AppProperty';
import { fetchPatientsVisitsDetailsSet } from '../../actions/PatientsVisitsDetails';

// react-bootstrap
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

// components
import VisitsDetail from './VisitsDetail';
import VisitsReferrals from './VisitsReferrals';
import PtScreen from './PtScreen';
import PtPhysicalExam from './PtPhysicalExam';
import PtDiag from './PtDiag';
import PtRxOrder from './PtRxOrder';
import AdmitData from './AdmitData';
import PtXray from './PtXray';
import PtLab from './PtLab';
import PtDental from './PtDental';
import PtAppointment from './PtAppointment';
import PtDoctorNote from './PtDoctorNote';
import TabPanel from '../Utility/TabPanel';

// mui
import DirectionsWalk from '@material-ui/icons/DirectionsWalk';
import AirlineSeatFlat from '@material-ui/icons/AirlineSeatFlat';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

const session = orm.session();

function VisitsTypeTabs({ bid, appData }) {
  const [value, setValue] = useState(0);
  const [visitData, setvisitData] = useState(null);
  const [bidData, setDataBid] = useState(false);
  const [visitAdmitData, setVisitAdmitData] = useState(null);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const setDataBidFc = bid => {
    setDataBid(bid);
  };

  const setvisitDataFc = bid => {
    const visits = appData.emrData.visits;

    session.Visits.delete({
      where(record) {
        return record.bid === bid;
      },
    });

    for (var val of visits) {
      session.Visits.upsert(val);
    }

    let visitsData = [];

    visitsData = session.Visits.all()
      .filter(Visits => Visits.bid === bid)
      .filter(Visits => Visits.class === 'AMB')
      .toRefArray();

    let visitsAdmitData = [];

    if (visitsData.length > 0 && visitsData[0].an) {
      visitsAdmitData = session.Visits.all()
        .filter(Visits => Visits.an === visitsData[0].an)
        .filter(Visits => Visits.class === 'IMP')
        .toRefArray();
    }

    setVisitAdmitData(visitsAdmitData);

    setValue(0);

    setvisitData({ visitsData });
  };

  useEffect(() => {
    if (bid !== bidData) {
      setDataBidFc(bid);
      setvisitDataFc(bid);
    }
  });

  return (
    <Paper square>
      {visitData && (
        <div>
          {visitData.visitsData.map((itemsV, indexV) => (
            <div key={indexV}>
              <Tabs
                value={value}
                indicatorColor='secondary'
                textColor='secondary'
                onChange={handleChange}
                centered
                className='MuiTabs-centered-custom'
              >
                <Tab label='OPD' className='icon-button' icon={<DirectionsWalk />} />
                <Tab
                  label='IPD'
                  className={itemsV.an ? 'icon-button' : 'icon-button object-hidden'}
                  icon={<AirlineSeatFlat />}
                />
              </Tabs>
            </div>
          ))}
          <div className='margin-bottom-8px'>
            <TabPanel className='TabPanel-custom' value={value} index={0}>
              <div className='margin-bottom-8px'>
                <VisitsDetail bid={bid} />
              </div>

              {visitData.visitsData.map((items, index) => (
                <div key='index'>
                  <div className='margin-bottom-8px'>{items.referrals && <VisitsReferrals bid={bid} />}</div>
                  <div className='margin-bottom-8px' key='index'>
                    {items.doctorNotes.length > 0 && (
                      <Row>
                        <Col lg={12} className='margin-bottom-8px'>
                          <PtDoctorNote bid={bid} dataType={'OPD'} />
                        </Col>
                      </Row>
                    )}

                    {items.appointments.length > 0 && (
                      <Row>
                        <Col lg={12} className='margin-bottom-8px'>
                          <PtAppointment bid={bid} dataType={'OPD'} />
                        </Col>
                      </Row>
                    )}

                    <Row>
                      <Col lg={12} className='margin-bottom-8px'>
                        <PtScreen bid={bid} />
                      </Col>
                    </Row>

                    <Row>
                      <Col lg={6} className='margin-bottom-8px'>
                        <PtPhysicalExam bid={bid} />
                      </Col>
                      <Col lg={6} className='margin-bottom-8px'>
                        <PtDiag bid={bid} dataType={'OPD'} />
                      </Col>
                    </Row>

                    {items.dentals.length > 0 && (
                      <Row>
                        <Col lg={12} className='margin-bottom-8px'>
                          <PtDental bid={bid} />
                        </Col>
                      </Row>
                    )}

                    {items.xrays.length > 0 && (
                      <Row>
                        <Col lg={12} className='margin-bottom-8px'>
                          <PtXray bid={bid} dataType={'OPD'} />
                        </Col>
                      </Row>
                    )}
                    {items.billingItems.length > 0 && (
                      <Row>
                        <Col lg={12} className='margin-bottom-8px'>
                          <PtRxOrder bid={bid} cid={items.cid} dataType={'OPD'} />
                        </Col>
                      </Row>
                    )}
                    {items.labResults.length > 0 && (
                      <Row>
                        <Col lg={12} className='margin-bottom-8px'>
                          <PtLab bid={bid} cid={items.cid} dataType={'OPD'} />
                        </Col>
                      </Row>
                    )}
                  </div>
                </div>
              ))}
            </TabPanel>
            <TabPanel className='TabPanel-custom' value={value} index={1}>
              {visitAdmitData.map((items, index) => (
                <div key={index}>
                  {items.an && (
                    <div className='margin-bottom-8px'>
                      <Row>
                        <Col lg={12} className='margin-bottom-8px'>
                          <AdmitData bid={bid} an={items.an} />
                        </Col>
                      </Row>

                      {items.appointments.length > 0 && (
                        <Row>
                          <Col lg={12} className='margin-bottom-8px'>
                            <PtAppointment bid={bid} an={items.an} dataType={'IPD'} />
                          </Col>
                        </Row>
                      )}

                      <Row>
                        <Col lg={12} className='margin-bottom-8px'>
                          <PtDiag bid={bid} an={items.an} dataType={'IPD'} />
                        </Col>
                      </Row>

                      {items.xrays.length > 0 && (
                        <Row>
                          <Col lg={12} className='margin-bottom-8px'>
                            <PtXray bid={bid} an={items.an} dataType={'IPD'} />
                          </Col>
                        </Row>
                      )}
                      {items.billingItems.length > 0 && (
                        <Row>
                          <Col lg={12} className='margin-bottom-8px'>
                            <PtRxOrder bid={bid} an={items.an} cid={items.cid} dataType={'IPD'} />
                          </Col>
                        </Row>
                      )}
                      {items.labResults.length > 0 && (
                        <Row>
                          <Col lg={12} className='margin-bottom-8px'>
                            <PtLab bid={bid} cid={items.cid} an={items.an} dataType={'IPD'} />
                          </Col>
                        </Row>
                      )}
                    </div>
                  )}
                </div>
              ))}
            </TabPanel>
          </div>
        </div>
      )}
    </Paper>
  );
}

function mapStateToProps(state) {
  return {
    appProperty: state.appProperty,
    appData: state.appData,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    appPropertySet: appPropertyData => dispatch(appPropertySet(appPropertyData)),
    fetchPatientsVisitsDetailsSet: dataRequest => dispatch(fetchPatientsVisitsDetailsSet(dataRequest)),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(VisitsTypeTabs));
