import React, { useState } from 'react';
import { useForm } from 'react-hook-form';

// mui
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import { DatePicker } from '@material-ui/pickers';

export default function DatePickerDialog({ open, onSubmit, onClose }) {
  const thisYear = new Date().getFullYear();
  const { handleSubmit } = useForm();

  const [selectedDate, setSelectedDate] = useState(new Date());

  const onSubmitForm = () => {
    console.log(selectedDate);
    onSubmit({ selectedDate });
  };

  return (
    <Dialog
      fullWidth
      maxWidth='xs'
      open={open}
      onClose={() => onClose()}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      className='refer-dialog-accept'
    >
      <form onSubmit={handleSubmit(onSubmitForm)}>
        <DialogTitle id='alert-dialog-title'>
          <strong>คำนวณ Billing ใหม่</strong>
        </DialogTitle>
        <DialogContent>
          <DatePicker
            autoOk
            fullWidth
            margin='normal'
            label='เลือกเดือนที่ต้องการคำนวณ Billing ใหม่'
            views={['month']}
            format={'MMMM'}
            minDate={new Date([thisYear, 1, 1])}
            maxDate={new Date([thisYear, 12, 31])}
            value={selectedDate}
            onChange={e => setSelectedDate(e)}
          />
        </DialogContent>
        <DialogActions>
          <Button type='submit' color='primary' variant='contained'>
            ทำการคำนวน Billing
          </Button>
          <Button onClick={() => onClose()} color='secondary' variant='contained'>
            ยกเลิก
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
