import axios from 'axios';

// functions
import { getApiUrl } from "../../functions/Commons";

export const FetchHospitalList = ({ idToken }) => {
  let dispatch = {};
  const url = `${getApiUrl()}/v1/lists/hospital`;

  return axios
    .get(url, {
      headers: { authorization: `Bearer ${idToken}` },
    })
    .then(res => {
      dispatch = {
        hospitalList: res.data,
        FetchingStatus: false,
      };

      return dispatch;
    })
    .catch(err => {
      console.log('fetch hospital list axios error');

      dispatch = {
        FetchingStatus: false,
      };

      return dispatch;
    });
};
