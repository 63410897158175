export const DBConfig = {
  name: 'EverHieDB',
  version: 5,
  objectStoresMeta: [
    {
      store: 'notifications',
      // storeConfig: { keyPath: 'id', autoIncrement: true },
      storeConfig: { keyPath: 'id', options: { unique: true } },
      storeSchema: [
        { name: 'keyId', keypath: 'keyId', options: { unique: false } },
        { name: 'key1', keypath: 'key1', options: { unique: false } },
        { name: 'key2', keypath: 'key2', options: { unique: false } },
        {
          name: 'notifyDateTime',
          keypath: 'notifyDateTime',
          options: { unique: false },
        },
        {
          name: 'notifyType',
          keypath: 'notifyType',
          options: { unique: false },
        },
        {
          name: 'notifyLink',
          keypath: 'notifyLink',
          options: { unique: false },
        },
        { name: 'datas', keypath: 'datas', options: { unique: false } },
        {
          name: 'notifyRead',
          keypath: 'notifyRead',
          options: { unique: false },
        },
      ],
    },
  ],
};
