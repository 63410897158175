import { APPDATA } from '../constants/appData';
import ChargeSummary from '../api/Billings/ChargeSummary';
import CalculateSummary from '../api/Billings/CalculateSummary';

export function fetchChargeSummary(dataRequest) {
  return {
    type: APPDATA,
    payload: ChargeSummary(dataRequest),
  };
}

export function fetchCalculateChargeSummary(dataRequest) {
  return {
    type: APPDATA,
    payload: CalculateSummary(dataRequest),
  };
}
