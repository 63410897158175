import axios from 'axios';

// functions
import { getApiUrl } from "../../functions/Commons";

export default async req => {
  const { idToken, body } = req;
  let dispatch = {};

  await axios
    .put(`${getApiUrl()}/v1/user`, body, {
      headers: { Authorization: `Bearer ${idToken}` },
    })
    .then(res => {
      dispatch = {
        FetchingStatus: false,
        alert: {
          show: false,
          msg: '',
          type: 'success',
        },
      };
    })
    .catch(err => {
      dispatch = {
        FetchingStatus: false,
        alert: {
          show: true,
          msg: err.response.data.msg,
          type: 'error',
        },
      };
    });

  return dispatch;
};
