import React, { Component } from 'react';
import { connect } from 'react-redux';
import Routes from './routes/index';
import { appPropertySet } from './actions/AppProperty';
import { withRouter } from 'react-router-dom';
import config from 'react-global-configuration';

import { DBConfig } from './indexedDB/DBConfig';
import { initDB } from 'react-indexed-db';

/*
function deleteOldIndexedDB(dbName, actualVersion) {
	if (!indexedDB) {
		return false
	}

	var req = indexedDB.open(dbName)
	req.onsuccess = function(event) {
		var version
		if (event.srcElement !== undefined) {
			version = event.srcElement.result.version //chrome
		} else {
			version = event.target.result.version //firefox
		}

		event.target.result.close()
		if (version !== actualVersion && version !== 1) {
			indexedDB.deleteDatabase(dbName)
		}
	}
}
*/

initDB(DBConfig);

let configuration = {};

if (process.env.NODE_ENV === 'development') {
  configuration = window.__ENV__.ROOT_CONFIG_DEV;
  configuration.ROOT_API = process.env.REACT_APP_ROOT_API;
} else {
  configuration = window.__ENV__.ROOT_CONFIG;
  configuration.ROOT_API = process.env.REACT_APP_ROOT_API;
}
config.set(configuration);

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      theme: 'light',
      activeArticleId: 1,
    };
    this.toggleTheme = this.toggleTheme.bind(this);
    this.setAppData = this.setAppData.bind(this);
  }

  toggleTheme() {
    const theme = this.state.theme === 'light' ? 'dark' : 'light';
    document.documentElement.classList.add('color-theme-in-transition');
    this.setState({ theme });
    document.documentElement.setAttribute('theme', theme);

    window.setTimeout(() => {
      document.documentElement.classList.remove('color-theme-in-transition');
    }, 1000);
  }

  componentDidMount() {
    const theme = localStorage.getItem('theme') === 'dark' ? 'dark' : 'light';
    document.documentElement.classList.add('color-theme-in-transition');
    localStorage.setItem('theme', theme);
    document.documentElement.setAttribute('theme', theme);
    window.setTimeout(() => {
      document.documentElement.classList.remove('color-theme-in-transition');
    }, 1000);

    const appPropertyData = {
      ROOT_API: config.get('ROOT_API'),
      mailHost: config.get('mailHost'),
      visitLimit: config.get('visitLimit'),
      ScKey: config.get('ScKey'),
      theme: localStorage.getItem('theme'),
      FetchingStatus: false,
    };

    this.setAppData(appPropertyData);
  }

  setAppData(appPropertyData) {
    this.props.appPropertySet(appPropertyData);
  }

  render() {
    return (
      <div>
        <Routes />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    appProperty: state.appProperty,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    appPropertySet: appPropertyData => dispatch(appPropertySet(appPropertyData)),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
