import { combineReducers } from 'redux';
import { createReducer } from 'redux-orm';
import appProperty from './appProperty';
import appData from './appData';

import orm from '../models/index';

export default combineReducers({
  orm: createReducer(orm),
  appProperty,
  appData,
});
