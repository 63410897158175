import axios from 'axios';

// functions
import { getApiUrl } from "../../functions/Commons";

export default async body => {
  const { idToken, hospCode, submissionBody, rulesSubmissionBody } = body;
  try {
    const result = await axios.post(`${getApiUrl()}/v1/billing/categories/${hospCode}`, submissionBody, {
      headers: { Authorization: `Bearer ${idToken}` },
    });
    const resultRules = await axios.post(
      `${getApiUrl()}/v1/billingRules/rules/${hospCode}`,
      rulesSubmissionBody,
      { headers: { Authorization: `Bearer ${idToken}` } }
    );
    return { result, resultRules };
  } catch (e) {
    throw new Error(e);
  }
};
