import axios from 'axios';

// functions
import { getApiUrl } from "../../functions/Commons";

export default dataRequest => {
  return new Promise((resolve, reject) => {
    setTimeout(async () => {
      const { idToken, hospCode, categoryObjectId } = dataRequest;
      await axios
        .post(
          `${getApiUrl()}/v1/billingstatus/providerSubmitBillForReview`,
          { hospCode, categoryObjectId },
          { headers: { Authorization: `Bearer ${idToken}` } }
        )
        .then(res => {
          const billingData = {
            FetchingStatus: false,
            chargeModal: false,
          };

          return resolve(billingData);
        })
        .catch(err => {
          const billingData = {
            FetchingStatus: false,
            alert: {
              show: true,
              msg: 'ทำการเรียกเก็บไม่สำเร็จ',
              type: 'error',
            },
          };

          return resolve(billingData);
        });
    }, 200);
  });
};
