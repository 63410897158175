import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import dayjs from 'dayjs';

//assets
import printUC from '../../../assets/pdf/genUC';
import printAliens from '../../../assets/pdf/genAliens';
import printSS from '../../../assets/pdf/genSS';

//functions
import { ThaiDate } from '../../../functions/FuncDateTimes';
import { shortHospName, getBillingStatusConfig, formatNum, sameStatus } from '../../../functions/FuncPerjer';

//actions
import { openChargeModal } from '../../../actions/BillingsCharge';
import { fetchChargeSummary } from '../../../actions/BillingSummary';

//styles
import { Container, TableRow, TableCell, Status } from '../styles';

//components
import ChargeModal from '../ChargeModal/ChargeModal';

//mui
import PrintIcon from '@material-ui/icons/Print';
import EventNoteIcon from '@material-ui/icons/EventNote';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import Button from '@material-ui/core/Button';
import { IconButton } from '@material-ui/core';

const ChargeSummary = ({ match: { params } }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { appData } = useSelector(state => state);
  const { idToken, hospCode, billingSummary, FetchingStatus, selectedHospmain, chargeModal, loginData } = appData;
  const { monthIndex, selectedHosp } = params;
  const asProvider = history.location.pathname.indexOf('/to') !== -1 ? true : false;

  useEffect(() => {
    localStorage.removeItem('page');
  }, []);

  useEffect(() => {
    if (loginData?.role?.toUpperCase() === 'SUPERADMIN') {
      handleFetchChargeSummary({
        idToken,
        hospCode: asProvider ? history.location?.state?.provider?.hospCode : history.location?.state?.payer?.hospCode,
        selectedHospmain: asProvider
          ? history.location?.state?.payer?.hospCode
          : history.location?.state?.provider?.hospCode,
        month: Number(monthIndex) - 1,
        asProvider,
        year: history.location.state?.year,
      });
    } else {
      handleFetchChargeSummary({
        idToken,
        hospCode,
        selectedHospmain: selectedHosp,
        month: Number(monthIndex) - 1,
        asProvider,
        year: history.location.state?.year,
      });
    }
  }, [dispatch, idToken, hospCode, monthIndex, selectedHosp, chargeModal, asProvider, loginData]);

  const handleFetchChargeSummary = ({ idToken, hospCode, selectedHospmain, month, asProvider, year }) => {
    dispatch(
      fetchChargeSummary({
        idToken,
        hospCode,
        selectedHospmain,
        month,
        asProvider,
        year,
      })
    );
  };

  const getPatients = row => {
    history.push({
      pathname: `${monthIndex}/${row.categoryCode}/patients`,
      search: `?categoryName=${row.categoryName}`,
      state: {
        summaryStatus: row.status,
        categoryId: row._id,
        year: history.location?.state?.year,
        provider: history.location?.state?.provider,
        payer: history.location?.state?.payer,
      },
    });
  };

  const checkCategory = data => {
    const datepdf = new Date(new Date().getFullYear(), monthIndex, 1);
    const hosname = selectedHospmain.hospName;
    switch (data.categoryCode?.toUpperCase()) {
      //บัตรทอง
      case 'UC':
      case 'UC1':
      case 'UC 3':
      case 'UC500':
      case 'UC700':
      case '5A4534CF-937F-452C-A21A-686CCCCB8AF1':
      case 'AE1F8BFC-E924-4B5F-B901-7299368CDE57':
      case '9EED58DB-DDB6-4425-B58F-1478DBDE49BB':
      case '0DF4D320-5A07-40BD-82A1-A7450B8F6881':
        return printUC(data, hosname, datepdf, hospCode);
      //ต่างด้าว
      case 'AL':
        return printAliens(data, hosname, datepdf, hospCode);
      //ประกันสังคม
      case 'SS':
      case 'SSO':
      case '3F51DCFD-9174-4FF4-B5EF-E5B5DFD1EA4A':
        return printSS(data, hosname, datepdf, hospCode);
      default:
        console.log(`data.category of "${data.categoryCode}" does not matched`);
    }
  };

  const buildButton = ({ row, role }) => {
    if (role?.toUpperCase() !== 'SUPERADMIN') {
      const isAccepted = row.status === 'ACCEPTED' ? true : false;
      const isRejected = row.status === 'REJECTED' ? true : false;
      const isComplete = row.status === 'COMPLETE' ? true : false;
      const isEdited = row.status === 'EDITED' ? true : false;
      const isProcessing = row.status === 'PROCESSING' || row.status === 'PROCESSING_EDITED' ? true : false;

      const selectedCategory = {
        categoryCode: row.categoryCode,
        categoryName: row.categoryName,
      };

      if (asProvider) {
        if (isAccepted || isComplete || isEdited) {
          return '-';
        } else if (isRejected) {
          return (
            <Button
              onClick={() => dispatch(openChargeModal(selectedCategory))}
              variant='outlined'
              size='small'
              disabled={!isRejected}
            >
              จบการเบิกจ่าย
            </Button>
          );
        } else {
          return (
            <Button
              onClick={() => dispatch(openChargeModal(selectedCategory))}
              variant='outlined'
              size='small'
              disabled={!isProcessing}
            >
              ส่งการเบิกจ่ายเงิน
            </Button>
          );
        }
      } else {
        if (isAccepted || isComplete || isEdited || isProcessing) {
          return '-';
        } else {
          return (
            <Button
              onClick={() => dispatch(openChargeModal(selectedCategory))}
              variant='outlined'
              size='small'
              disabled={isRejected}
            >
              ตอบรับการเบิกจ่าย
            </Button>
          );
        }
      }
    } else {
      return '-';
    }
  };

  return (
    <>
      <Container>
        <div className='header'>
          <EventNoteIcon className='header-icon' />
          <h5 className='title'>
            {asProvider ? 'เรียกเก็บ' : 'ตามจ่าย'} -{' '}
            {ThaiDate(dayjs([history.location.state?.year, monthIndex, 1])).substr(2, 10)}
          </h5>
        </div>
        {!FetchingStatus && selectedHospmain && (
          <div className='table-head'>
            <h4>
              <b>{shortHospName(selectedHospmain.hospName)}</b>
            </h4>
          </div>
        )}
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align='center'>สิทธิหลัก</TableCell>
              <TableCell align='right'>จำนวนผู้ป่วย</TableCell>
              <TableCell align='right'>จำนวน visit</TableCell>
              <TableCell align='right'>ยอดเงินตามจริง (฿)</TableCell>
              <TableCell align='right'>ยอดที่คำนวณ (฿)</TableCell>
              <TableCell align='right'>ยอดที่เรียกเก็บ (฿)</TableCell>
              <TableCell align='right'>สถานะ</TableCell>
              <TableCell align='center'></TableCell>
              <TableCell align='center'></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!FetchingStatus && billingSummary && billingSummary?.categories?.length > 0 ? (
              billingSummary.categories.map(row => {
                const billingIsAfterProcessing = asProvider || (row.status && !sameStatus(row.status, 'PROCESSING'));

                if (billingIsAfterProcessing) {
                  return (
                    <TableRow key={row._id}>
                      <TableCell align='center' component='th' scope='row' onClick={() => getPatients(row)}>
                        {row.categoryName}
                      </TableCell>
                      <TableCell align='right' onClick={() => getPatients(row)}>
                        {row.totalPatients}
                      </TableCell>
                      <TableCell align='right' onClick={() => getPatients(row)}>
                        {row.totalVisits}
                      </TableCell>
                      <TableCell align='right' onClick={() => getPatients(row)}>
                        {formatNum(row.totalPrice)}
                      </TableCell>
                      <TableCell align='right' onClick={() => getPatients(row)}>
                        {formatNum(row.totalCalcPrice)}
                      </TableCell>
                      <TableCell align='right' onClick={() => getPatients(row)}>
                        {formatNum(row.totalFinalPrice)}
                      </TableCell>
                      <TableCell align='right' onClick={() => getPatients(row)}>
                        <Status
                          color={row?.numDestAccepted > 0 ? '#54a5e7' : getBillingStatusConfig(row.status)?.color}
                        >
                          {getBillingStatusConfig(row.status)?.text}
                        </Status>
                      </TableCell>
                      <TableCell align='center'>{buildButton({ row, role: loginData?.role })}</TableCell>
                      <TableCell align='center'>
                        {asProvider && loginData?.role?.toUpperCase() !== 'SUPERADMIN' ? (
                          <IconButton onClick={() => checkCategory(row)}>
                            <PrintIcon />
                          </IconButton>
                        ) : (
                          '-'
                        )}
                      </TableCell>
                    </TableRow>
                  );
                } else {
                  return (
                    <TableRow key={row._id} onClick={() => getPatients(row)}>
                      <TableCell align='center' component='th' scope='row'>
                        {row.categoryName}
                      </TableCell>
                      <TableCell align='right'>{row.totalPatients}</TableCell>
                      <TableCell align='right'>{row.totalVisits}</TableCell>
                      <TableCell align='right'>-</TableCell>
                      <TableCell align='right'>-</TableCell>
                      <TableCell align='right'>-</TableCell>
                      <TableCell align='right'>
                        <Status
                          color={row?.numDestAccepted > 0 ? '#54a5e7' : getBillingStatusConfig(row.status)?.color}
                        >
                          {getBillingStatusConfig(row.status)?.text}
                        </Status>
                      </TableCell>
                      <TableCell align='center'>-</TableCell>
                      <TableCell align='center'>-</TableCell>
                    </TableRow>
                  );
                }
              })
            ) : (
              <TableRow>
                <TableCell colSpan={9} align='center' component='th' scope='row'>
                  ไม่พบข้อมูล
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </Container>
      {chargeModal && (
        <ChargeModal
          selectedHosp={selectedHosp}
          monthIndex={monthIndex}
          hospName={selectedHospmain && selectedHospmain.hospName}
          asProvider={asProvider}
        />
      )}
    </>
  );
};

export default ChargeSummary;
