import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import orm from '../../models/index';

// actions
import { appPropertySet } from '../../actions/AppProperty';
import { fetchPatientsVisitsDetailsSet } from '../../actions/PatientsVisitsDetails';

// react-bootstrap
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Alert from 'react-bootstrap/Alert';

// functions
import { ThaiDateTime } from '../../functions/FuncDateTimes';

// mui
import Card from '@material-ui/core/Card';

const session = orm.session();

function PtDoctorNote({ bid, dataType, an, appData, appPropertySet, fetchPatientsVisitsDetailsSet }) {
  const [visitData, setvisitData] = useState(null);
  const [bidData, setDataBid] = useState(false);

  const setDataBidFc = bid => {
    setDataBid(bid);
  };

  const setvisitDataFc = bid => {
    const visits = appData.emrData.visits;

    session.Visits.delete({
      where(record) {
        return record.bid === bid;
      },
    });

    for (var val of visits) {
      session.Visits.upsert(val);
    }

    let visitsData = [];

    if (dataType === 'OPD') {
      visitsData = session.Visits.all()
        .filter(Visits => Visits.bid === bid)
        // .filter((Visits) => Visits.class === 'AMB')
        .toRefArray();
    }

    if (dataType === 'IPD') {
      visitsData = session.Visits.all()
        .filter(Visits => Visits.an === an)
        .filter(Visits => Visits.class === 'IMP')
        .toRefArray();
    }

    setvisitData({ visitsData });
  };

  useEffect(() => {
    if (bid !== bidData) {
      setDataBidFc(bid);
      setvisitDataFc(bid);
    }
  });

  return (
    <div>
      {visitData && (
        <div>
          {visitData.visitsData.map((items, index) => (
            <div key={index}>
              {items.dentals && (
                <Card variant='outlined' className='visit-detail-card'>
                  <Row>
                    <Col lg={12}>
                      <h5>Doctor Note</h5>
                    </Col>
                  </Row>
                  {items.doctorNotes.map((itemsDoctorNote, indexDoctorNote) => (
                    <Alert variant='danger' key={indexDoctorNote} className='font-size-14px'>
                      <Row>
                        <Col lg={12}>
                          {itemsDoctorNote.doctorNoteText && (
                            <div>
                              {itemsDoctorNote.doctorNoteText.split('\r').map((item2, index2) => (
                                <div key={index2}> {item2}</div>
                              ))}
                            </div>
                          )}
                        </Col>
                      </Row>
                      <hr />
                      <Row>
                        <Col lg={6}>
                          <div className='font-size-10px'>
                            วันที่บันทึก : {ThaiDateTime(itemsDoctorNote.noteDateTime)}
                          </div>
                        </Col>
                        <Col lg={6}>
                          <div className='font-size-10px'>แพทย์ : {itemsDoctorNote.doctorName}</div>
                        </Col>
                      </Row>
                    </Alert>
                  ))}
                </Card>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

function mapStateToProps(state) {
  return {
    appProperty: state.appProperty,
    appData: state.appData,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    appPropertySet: appPropertyData => dispatch(appPropertySet(appPropertyData)),
    fetchPatientsVisitsDetailsSet: dataRequest => dispatch(fetchPatientsVisitsDetailsSet(dataRequest)),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PtDoctorNote));
