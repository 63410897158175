import axios from 'axios';

// functions
import { getApiUrl } from "../../functions/Commons";

export default async body => {
  const { idToken, hospCode, categoryId } = body;
  try {
    const result = await axios.delete(`${getApiUrl()}/v1/billing/categories/${hospCode}/${categoryId}`, {
      headers: { Authorization: `Bearer ${idToken}` },
    });
    return result;
  } catch (e) {
    throw new Error(e);
  }
};
