import { createStore, applyMiddleware } from 'redux';
import app from '../reducers';
import promiseMiddleware from 'redux-promise-middleware';

// import { composeWithDevTools } from 'redux-devtools-extension'
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';

export default function configureStore() {
  const initialState = {};
  const store = createStore(app, initialState, composeWithDevTools(applyMiddleware(promiseMiddleware)));
  return store;
}
