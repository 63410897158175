import axios from 'axios';

// functions
import { getApiUrl } from "../../functions/Commons";

export default req => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      const { idToken, page = 1, limit = 20, sortColumn = '', sortType = 0, filterText = '', filterList = [] } = req;

      let filter = {};
      if (filterList.length) {
        if (filterList[0].length) {
          filter.hospCode = filterList[0];
        }

        if (filterList[2].length) {
          filter.role = filterList[2];
        }
      }

      axios
        .get(`${getApiUrl()}/v1/user`, {
          headers: { authorization: `Bearer ${idToken}` },
          params: { page, limit, sortColumn, sortType, filterText, filter },
        })
        .then(res => {
          console.log(res);
          let data = {
            users: res.data.result,
            FetchingStatus: false,
            alert: {
              show: false,
              msg: '',
              type: null,
            },
          };

          return resolve(data);
        })
        .catch(err => {
          console.log('AXIOS ERROR: error !');
          return reject(err.response);
        });
    }, 200);
  });
};
