import styled from 'styled-components';

export const Container = styled.div`
  padding: 16px 16px 0 16px;
  text-align: left;
  .drugList {
    background-color: white;
    border-radius: 4px;
    padding: 15px;
    margin-bottom: 30px;

    h3,
    h4,
    h5 {
      color: #004c66;
    }

    .subHeading {
      color: grey;

      strong {
        margin-left: 20px;
      }
    }
  }
  .billing-comments {
    font-size: 12px;
    font-style: italic;
    margin-top: 10px;
  }
  .modal-dialog {
    margin-top: 10%;
  }
`;

export const Smodal = styled.div`
  padding: 0 16px;
  .fix {
    margin-left: 0px;
  }
  .billing-item-overview {
    margin-bottom: 20px;
  }
  .billingItemModal {
    table {
      width: 100%;
      font-size: 17px;
      text-align: left;
    }
    table td:first-child {
      text-align: right;
    }
    table td {
      padding: 2px 10px;
    }
  }
`;
