import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import orm from '../../models/index';

// actions
import { appPropertySet } from '../../actions/AppProperty';
import { fetchPatientsVisitsDetailsSet } from '../../actions/PatientsVisitsDetails';

// react-bootstrap
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

// mui
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';

const session = orm.session();

function PtDiag({ dataType, bid, an, appData }) {
  const [visitData, setvisitData] = useState(null);
  const [bidData, setDataBid] = useState(false);

  const setDataBidFc = bid => {
    setDataBid(bid);
  };

  const setvisitDataFc = bid => {
    const visits = appData.emrData.visits;

    session.Visits.delete({
      where(record) {
        return record.bid === bid;
      },
    });

    for (var val of visits) {
      session.Visits.upsert(val);
    }

    let visitsData = [];

    if (dataType === 'OPD') {
      visitsData = session.Visits.all()
        .filter(Visits => Visits.bid === bid)
        // .filter((Visits) => Visits.class === 'AMB')
        .toRefArray();
    }

    if (dataType === 'IPD') {
      visitsData = session.Visits.all()
        .filter(Visits => Visits.an === an)
        .filter(Visits => Visits.class === 'IMP')
        .toRefArray();
    }

    setvisitData({ visitsData });
  };

  useEffect(() => {
    if (bid !== bidData) {
      setDataBidFc(bid);
      setvisitDataFc(bid);
    }
  });

  return (
    <div>
      {visitData && (
        <div>
          {visitData.visitsData.map((items, index) => (
            <div key={index}>
              <Card variant='outlined' className='visit-detail-card'>
                <Row>
                  <Col lg={12}>
                    <h5>Diagnosis</h5>
                  </Col>
                </Row>
                <Row>
                  <Col lg={12}>
                    {items.diagText && (
                      <Grid container spacing={1} className='font-size-14px'>
                        <Grid item xs={12}>
                          Diag Text :
                          {items.diagText && (
                            <div style={{ paddingLeft: '8px' }}>
                              {items.diagText.split('\r').map((item2, index2) => (
                                <div key={index2}> {item2}</div>
                              ))}
                            </div>
                          )}
                        </Grid>
                      </Grid>
                    )}

                    {items.diagnosisOPDTexts.map((itemsDiag, indexDiag) => (
                      <div key={indexDiag}>
                        <Grid container spacing={1} className='font-size-12px'>
                          <Grid item xs={12}>
                            {indexDiag + 1}.
                          </Grid>
                        </Grid>
                      </div>
                    ))}

                    {items.diagnoses.map((itemsDiag, indexDiag) => (
                      <div key={indexDiag}>
                        <Grid container spacing={1} className='font-size-12px'>
                          <Grid item xs={12}>
                            {indexDiag + 1}. {itemsDiag.icd10} : {itemsDiag.icd10Name} ({itemsDiag.diagTypeName})
                          </Grid>
                        </Grid>
                      </div>
                    ))}
                  </Col>
                </Row>
              </Card>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

function mapStateToProps(state) {
  return {
    appProperty: state.appProperty,
    appData: state.appData,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    appPropertySet: appPropertyData => dispatch(appPropertySet(appPropertyData)),
    fetchPatientsVisitsDetailsSet: dataRequest => dispatch(fetchPatientsVisitsDetailsSet(dataRequest)),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PtDiag));
