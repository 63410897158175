import axios from 'axios';

// functions
import { getApiUrl } from "../../functions/Commons";

export default async body => {
  const { idToken, category, hospCode } = body;
  try {
    const result = await axios.get(`${getApiUrl()}/v1/billingRules/rules/${hospCode}/${category}`, {
      headers: { Authorization: `Bearer ${idToken}` },
    });
    return result.data;
  } catch (e) {
    throw new Error(e);
  }
};
