export function StrEncrypt(st, key) {
  let CryptoJS = require('crypto-js');

  let key2 = key + 'cGVyamVy';

  let Encrypted = '';

  if (st && st !== '') {
    let b64 = CryptoJS.AES.encrypt(st, key2).toString();
    let e64 = CryptoJS.enc.Base64.parse(b64);
    Encrypted = e64.toString(CryptoJS.enc.Hex);
  } else {
    Encrypted = '';
  }

  return Encrypted;
}

export function StrDecrypt(st, key) {
  let CryptoJS = require('crypto-js');

  let key2 = key + 'cGVyamVy';

  let Decrypt = '';

  if (st && st !== '') {
    let reb64 = CryptoJS.enc.Hex.parse(st);
    let bytes = reb64.toString(CryptoJS.enc.Base64);
    let decrypt = CryptoJS.AES.decrypt(bytes, key2);
    Decrypt = decrypt.toString(CryptoJS.enc.Utf8);
  } else {
    Decrypt = '';
  }

  return Decrypt;
}

export function shortHospName(str) {
  if (str) {
    str = str.replace('โรงพยาบาล', 'รพ.');
    str = str.replace('ส่งเสริมสุขภาพตำบล', 'สต.');
    str = str.replace('สำนักงานสาธารณสุขจังหวัด', 'สสจ.');
    str = str.replace('สำนักงานสาธารณสุขอำเภอ', 'สสอ.');
    str = str.replace('โรงพยาบาลศูนย์', 'รพศ.');
    str = str.replace('โรงพยาบาลชุมชน', 'รพช.');
    str = str.replace('สถานีอนามัย', 'สอ.');
    str = str.replace('ศูนย์สุขภาพชุมชน', 'ศสช.');
  }
  let newStr = str;
  return newStr;
}

const BILLING_STATUSES = {
  PROCESSING: { text: 'รอส่งเรียกเก็บ', color: '#F57F17' },
  DEST_ACCEPTED: { text: 'ปลายทางตรวจแล้ว', color: '#64DD17' },
  SOURCE_ACCEPTED: { text: 'ต้นทางตรวจแล้ว', color: '#64DD17' },
  PENDING: { text: 'รอผู้จ่ายตรวจสอบ', color: '#0D47A1' },
  ACCEPTED: { text: 'ผู้จ่ายตกลงจ่าย', color: '#64DD17' },
  REJECTED: { text: 'ผู้จ่ายไม่ตกลงจ่าย', color: '#E64A19' },
  EDITED: { text: 'แก้ไขแล้ว', color: '#00BFA5' },
  COMPLETE: { text: 'จบการเรียกเก็บ', color: '#64DD17' },
  NULL: { text: '-', color: null },
};

export const getBillingStatusConfig = billingStatus => {
  return billingStatus ? BILLING_STATUSES[billingStatus.toUpperCase()] : BILLING_STATUSES['NULL'];
};

export const formatNum = num => Number(num).toFixed(2);

export const sameStatus = (billingStatusFromDb, compare) => {
  return billingStatusFromDb.toUpperCase() === compare;
};

export const statusInArray = (billingStatusFromDb, compareArray) => {
  return compareArray.includes(billingStatusFromDb.toUpperCase());
};

export const hospDataFromHospCode = hospCode => {
  switch (hospCode) {
    case '10685':
      return {
        headerNumber: '๒',
        hospName: 'โรงพยาบาลสมุทรปราการ',
        address: ['๗๑ ถนนจักกะพาก อำเภอเมืองฯ', 'จังหวัดสมุทรปราการ ๑๐๒๗๐'],
        bank: {
          uc: {
            bankName: 'ธนาคารเพื่อการเกษตรและสหกรณ์การเกษตร สาขาสมุทรปราการ',
            accountName: 'โรงพยาบาลสมุทรปราการ (บัตรประกันสุขภาพ)',
            accountNumber: '๐๘๐-๒-๑๐๔๓๕-๕',
          },
          ss: {
            bankName: 'ธนาคารกรุงไทย สาขาปากน้ำ',
            accountName: 'เงินบำรุง',
            accountNumber: '๒๑๙-๑-๒๕๔๒๘-๔',
          },
          al: {
            bankName: 'ธนาคารกรุงไทย สาขาปากน้ำ',
            accountName: 'ประกันสุขภาพแรงงานต่างด้าวหลบหนีเข้าเมือง',
            accountNumber: '๒๑๙-๑-๖๐๕๗๓-๗',
          },
        },
        signature: {
          name: 'นายปฏิวัติ วงศ์งาม',
          position: 'รองผู้อำนวยการฝ่ายการแพทย์คนที่ ๒',
          subPosition: 'ปฏิบัติราชการแทนผู้อำนวยการโรงพยาบาลสมุทรปราการ',
        },
        footer: {
          name: 'ศูนย์ประกันสุขภาพ',
          tel: '๐-๒๑๗๓-๘๓๖๑-๕  ต่อ ๓๒๑๕',
          fax: '๐-๒๑๗๓-๘๓๖๗',
          copy: 'ฝ่ายบัญชี',
        },
      };
    // TODO: change it when get ss and al infomation
    case '10753':
      return {
        headerNumber: '๓๐๒',
        hospName: 'โรงพยาบาลบางพลี',
        address: ['อ.บางพลี จ.สมุทรปราการ ๑๐๕๔๐'],
        bank: {
          uc: {
            bankName: 'ธนาคาร ธ.ก.ส. สาขาบางพลี',
            accountName: 'เงินบำรุงโรงพยาบาลบางพลี',
            accountNumber: '๐๑๒ ๘ ๐๒๑๖๙๔๑ ๒',
          },
          ss: {
            bankName: 'ธนาคาร ธ.ก.ส. สาขาบางพลี',
            accountName: 'เงินบำรุงโรงพยาบาลบางพลี',
            accountNumber: '๐๑๒ ๘ ๐๒๑๖๙๔๑ ๒',
          },
          al: {
            bankName: 'ธนาคาร ธ.ก.ส. สาขาบางพลี',
            accountName: 'เงินบำรุงโรงพยาบาลบางพลี',
            accountNumber: '๐๑๒ ๘ ๐๒๑๖๙๔๑ ๒',
          },
        },
        signature: {
          name: 'นายพิเชษฐ พัวพันกิจเจริญ',
          position: 'ผู้อำนวยการโรงพยาบาลบางพลี',
        },
        footer: {
          name: 'งานประกันสุขภาพ',
          tel: '๐๒-๗๕๒๔๙๐๐-๑ ต่อ ๒๑๐๐,๒๕๒๙',
          fax: '๐๒-๓๑๒๒๐๕๔',
        },
      };
    // TODO: change it when get ss and al infomation
    case '28785':
      return {
        headerNumber: '๓๐๕',
        hospName: 'โรงพยาบาลบางเสาธง',
        address: ['ต.บางเสาธง อ.บางเสาธง', 'จ.สมุทรปราการ ๑๐๕๗๐'],
        bank: {
          uc: {
            name: 'ธนาคารเพื่อการเกษตรและสหกรณ์การเกษตร สาขาบางเสาธง',
            accountName: 'เงินบำรุงโรงพยาบาลบางเสาธง',
            accountNumber: '๐๒๐๐๓๗๙๐๔๕๐๓',
          },
          ss: {
            name: 'ธนาคารเพื่อการเกษตรและสหกรณ์การเกษตร สาขาบางเสาธง',
            accountName: 'เงินบำรุงโรงพยาบาลบางเสาธง',
            accountNumber: '๐๒๐๐๓๗๙๐๔๕๐๓',
          },
          al: {
            name: 'ธนาคารเพื่อการเกษตรและสหกรณ์การเกษตร สาขาบางเสาธง',
            accountName: 'เงินบำรุงโรงพยาบาลบางเสาธง',
            accountNumber: '๐๒๐๐๓๗๙๐๔๕๐๓',
          },
        },
        signature: {
          name: 'นางสาวภัคศรัณยธร ธนนันท์นภัส',
          position: 'ผู้อำนวยการโรงพยาบาลบางเสาธง',
        },
        footer: {
          name: 'กลุ่มงานประกันสุขภาพ',
          tel: '๐ ๒๑๗๐ ๙๓๓๒',
          fax: '๐ ๒๑๗๐ ๙๓๓',
        },
      };
    // TODO: change it when get ss and al infomation
    case '10752':
      return {
        headerNumber: '๓๐๑',
        hospName: 'โรงพยาบาลบางบ่อ',
        address: ['ถ.เทพารักษ์', 'อ.บางบ่อ จ.สมุทรปราการ ๑๐๕๖๐'],
        bank: {
          uc: {
            bankName: 'ธนาคารเพื่อการเกษตรและสหกรณ์การเกษตร สาขาบางบ่อ',
            accountName: 'เงินบำรุงโรงพยาบาลบางบ่อ',
            accountNumber: '๐๑๑๘๐๒๑๒๐๔๖๔',
          },
          ss: {
            bankName: 'ธนาคารเพื่อการเกษตรและสหกรณ์การเกษตร สาขาบางบ่อ',
            accountName: 'เงินบำรุงโรงพยาบาลบางบ่อ',
            accountNumber: '๐๑๑๘๐๒๑๒๐๔๖๔',
          },
          al: {
            bankName: 'ธนาคารเพื่อการเกษตรและสหกรณ์การเกษตร สาขาบางบ่อ',
            accountName: 'เงินบำรุงโรงพยาบาลบางบ่อ',
            accountNumber: '๐๑๑๘๐๒๑๒๐๔๖๔',
          },
        },
        signature: {
          name: 'นายวี โรจนศิรประภา',
          position: 'นายแพทย์ชำนาญการ รักษาการในตำแหน่ง',
          subPosition: 'ผู้อำนวยการโรงพยาบาลบางบ่อ',
        },
        footer: {
          name: 'งานการเงินและบัญชีกลุ่มงานบริหารงานทั่วไป',
          tel: '๐๒ ๓๓๘๑๑๓๓ ต่อ ๒๒๔',
          fax: '๐๒ ๗๐๘๓๔๑๐',
        },
      };
    // TODO: change it when get ss and al infomation
    case '10755':
      return {
        headerNumber: '๓๐๔',
        hospName: 'โรงพยาบาลพระสมุทรเจดีย์สวาทยานนท์',
        address: ['อ.พระสมุทรเจดีย์ จ.สมุทรปราการ ๑๐๒๙๐'],
        bank: {
          uc: {
            bankName: 'ธนาคารเพื่อการเกษตรและสหกรณ์การเกษตร สาขาพระสมุทรเจดีย์',
            accountName: 'โรงพยาบาลพระสมุทรเจดีย์สวาทยานนท์',
            accountNumber: '๐๘๐-๒-๑๕๒๑๖-๒',
          },
          ss: {
            bankName: 'ธนาคารเพื่อการเกษตรและสหกรณ์การเกษตร สาขาพระสมุทรเจดีย์',
            accountName: 'โรงพยาบาลพระสมุทรเจดีย์สวาทยานนท์',
            accountNumber: '๐๘๐-๒-๑๕๒๑๖-๒',
          },
          al: {
            bankName: 'ธนาคารเพื่อการเกษตรและสหกรณ์การเกษตร สาขาพระสมุทรเจดีย์',
            accountName: 'โรงพยาบาลพระสมุทรเจดีย์สวาทยานนท์',
            accountNumber: '๐๘๐-๒-๑๕๒๑๖-๒',
          },
        },
        signature: {
          name: 'นายจอมเทพ หวังสันติตระกูล',
          position: 'ผู้อํานวยการโรงพยาบาลพระสมุทรเจดีย์สวาทยานนท์',
        },
        footer: {
          name: 'กลุ่มงานประกันสุขภาพยุทธศาสตร์และสารสนเทศทางการแพทย์',
          tel: '๐ ๒๔๒๕ ๙๔๐๗ ต่อ ๑๑๒๑',
          fax: '๐ ๒๔๒๕ ๙๗๓๐',
        },
      };
    default:
      return '';
  }
};
