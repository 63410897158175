import axios from 'axios';

// functions
import { getApiUrl } from "../../functions/Commons";

const DeleteUser = async ({ idToken, username }) => {
  try {
    const result = await axios.post(
      `${getApiUrl()}/v1/user/delete/${username}`,
      {},
      {
        headers: { Authorization: `Bearer ${idToken}` },
      }
    );
    return result;
  } catch (e) {
    throw new Error(e);
  }
};

export default DeleteUser;
