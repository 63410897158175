import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { withRouter } from 'react-router-dom';

// actions
import { appPropertySet } from '../actions/AppProperty';
import { fetchLogout } from '../actions/logout';

// assets
import PjHIS from '../images/ever_logo.8c71ea29.png';

// components
import BarApp from './NavigationBar/BarApp';
import BarNotify from './NavigationBar/BarNotify';

// mui
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import HomeIcon from '@material-ui/icons/Home';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import MapIcon from '@material-ui/icons/Map';
import ReceiptIcon from '@material-ui/icons/Receipt';
import AssignmentReturnedIcon from '@material-ui/icons/AssignmentReturned';
import AssignmentReturnIcon from '@material-ui/icons/AssignmentReturn';
import ContactlessIcon from '@material-ui/icons/Contactless';
import SendIcon from '@material-ui/icons/Send';
import AssessmentIcon from '@material-ui/icons/Assessment';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Menu from '@material-ui/core/Menu';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import Brightness6Icon from '@material-ui/icons/Brightness6';
import SettingsIcon from '@material-ui/icons/Settings';
import MenuIcon from '@material-ui/icons/Menu';
import MenuOpenIcon from '@material-ui/icons/MenuOpen';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  MuiButtonCustom: {
    paddingTop: '6px',
    paddingBottom: '6px',
  },
  large: {
    backgroundColor: '#E83D84',
    padding: '8px 4px 2px 4px',
    marginTop: '2px',
    visibility: 'hidden',
  },
  large2: {
    backgroundColor: '#222222',
    padding: '8px 4px 2px 4px',
    marginTop: '8px',
    left: '20px',
  },
  large3: {
    backgroundColor: '#E83D84',
    padding: '8px 4px 2px 4px',
    marginTop: '2px',
    marginRight: '10px',
  },
  large4: {
    backgroundColor: '#E83D84',
    padding: '8px 4px 2px 4px',
    marginTop: '8px',
    left: '20px',
  },
  large5: {
    backgroundColor: '#E83D84',
    marginTop: '8px',
    left: '20px',
  },
  large6: {
    backgroundColor: '#E83D84',
    marginTop: '2px',
    marginRight: '10px',
  },
  overflow_x_auto: {
    overflow: 'auto',
  },
  RightMenu: {
    width: '300px',
    marginLeft: 'auto',
    textAlign: 'right',
  },
  RightMenuIcon: {
    float: 'right',
    padding: '4px',
  },
  RightMenuIconAvatar: {
    float: 'right',
    paddingLeft: '4px',
    paddingRight: '4px',
  },
}));

function NavigationMain({ appData, appPropertySet, fetchLogout, location }) {
  const classes = useStyles();
  const [state, setState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const [referOpen, setReferOpen] = useState(false);
  const [billingOpen, setBillingOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [PageLocation, setPageLocation] = useState('');

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const replaceLocation = st => {
    let re = /-/gi;
    return st.replace(re, '');
  };

  const setPageLocationFc = () => {
    setPageLocation(location.pathname);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickRefer = () => {
    setReferOpen(!referOpen);
  };

  const handleClickBilling = () => {
    setBillingOpen(!billingOpen);
  };

  const toggleDrawer = (side, open) => event => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setState({ ...state, [side]: open });
  };

  const toggleTheme = () => () => {
    const theme = localStorage.getItem('theme') === 'light' ? 'dark' : 'light';
    document.documentElement.classList.add('color-theme-in-transition');
    localStorage.setItem('theme', theme);
    document.documentElement.setAttribute('theme', theme);

    const appPropertyData = {
      theme: theme,
      FetchingStatus: false,
    };

    appPropertySet(appPropertyData);

    handleClose();

    window.setTimeout(() => {
      document.documentElement.classList.remove('color-theme-in-transition');
    }, 1000);
  };

  const logoutApp = previousUser => () => {
    const appData = {
      loginData: null,
      loginStatus: false,
      idToken: '',
      FetchingStatus: false,
      previousUser,
    };

    handleClose();
    sessionStorage.setItem('Token', '');
    localStorage.setItem('Token', '');
    fetchLogout(appData);
  };

  const openUserManualInNewTab = () => {
    window.open(
      'https://docs.google.com/document/d/e/2PACX-1vTycx9h6sMpUwLKEy2DIqE7ugqhyIcO7dGQcDZJlKL0P2YGJfZk5U4t2zcNOQu9yQ/pub'
    );
    toggleDrawer('left', false);
  };
  const sideList = (side, appData) => (
    <div className='navbar-left' role='presentation' onKeyDown={toggleDrawer(side, false)}>
      <Grid container className='app-name-text-bg-color app-tool-bar'>
        <div className='float-left app-name'>
          <h1 className='app-name-text font-family-custom'>EverHIE</h1>
        </div>
        <div className='float-left'>
          <Avatar className={classes.large5 + ' cursor-pointer'} onClick={toggleDrawer('left', false)}>
            <MenuOpenIcon style={{ fontSize: '24px' }} />
          </Avatar>
        </div>
      </Grid>

      <Grid
        className={`${classes.overflow_x_auto} navbar-scrollbar-color left-menu`}
        style={{ maxHeight: `${height - 58}px` }}
      >
        <List>
          <ListItem
            button
            component={NavLink}
            to='/'
            exact
            className={`${classes.MuiButtonCustom} ${
              replaceLocation(PageLocation).indexOf(replaceLocation('/')) === 0 &&
              PageLocation.length === 1 &&
              'ListItem-active'
            } `}
            activeClassName='ListItem-active'
            onClick={toggleDrawer('left', false)}
          >
            <ListItemIcon>
              <HomeIcon />
            </ListItemIcon>
            <ListItemText primary='Home' />
          </ListItem>
        </List>

        {/* {appData?.permission?.viewEmr && (
          <>
            <Divider />

            <List>
              <ListItem
                button
                component={NavLink}
                to='/patient-emr'
                exact
                className={`${classes.MuiButtonCustom} ${
                  replaceLocation(PageLocation).indexOf(replaceLocation('/patient-emr')) === 0 && 'ListItem-active'
                } `}
                activeClassName='ListItem-active'
                onClick={toggleDrawer('left', false)}
              >
                <ListItemIcon>
                  <AssignmentIndIcon />
                </ListItemIcon>
                <ListItemText primary='Patient (EMR)' />
              </ListItem>
            </List>
          </>
        )} */}

        <Divider />

        <List>
          <ListItem button onClick={handleClickRefer} className={classes.MuiButtonCustom}>
            <ListItemIcon>
              <SendIcon />
            </ListItemIcon>
            <ListItemText primary='Refer' />
            {referOpen ? <ExpandLess /> : <ExpandMore />}
          </ListItem>

          <Collapse in={referOpen} timeout='auto' unmountOnExit>
            <List component='div' disablePadding>
              {appData?.permission?.viewRefer && (
                <>
                  <ListItem
                    button
                    component={NavLink}
                    to='/refer-in'
                    exact
                    className={`${classes.MuiButtonCustom} ${
                      replaceLocation(PageLocation).indexOf(replaceLocation('/refer-in')) === 0 && 'ListItem-active'
                    } `}
                    activeClassName='ListItem-active'
                    onClick={toggleDrawer('left', false)}
                  >
                    <ListItemIcon>
                      <AssignmentReturnedIcon />
                    </ListItemIcon>
                    <ListItemText primary='Refer In' />
                  </ListItem>

                  <ListItem
                    button
                    component={NavLink}
                    to='/refer-out'
                    exact
                    className={`${classes.MuiButtonCustom} ${
                      replaceLocation(PageLocation).indexOf(replaceLocation('/refer-out')) === 0 && 'ListItem-active'
                    } `}
                    activeClassName='ListItem-active'
                    onClick={toggleDrawer('left', false)}
                  >
                    <ListItemIcon>
                      <AssignmentReturnIcon />
                    </ListItemIcon>
                    <ListItemText primary='Refer Out' />
                  </ListItem>
                </>
              )}

              {appData?.permission?.viewHospitalReport && (
                <ListItem
                  button
                  component={NavLink}
                  to='/hosp-refer-report'
                  exact
                  className={`${classes.MuiButtonCustom} ${
                    replaceLocation(PageLocation).indexOf(replaceLocation('/hosp-refer-report')) === 0 &&
                    'ListItem-active'
                  } `}
                  activeClassName='ListItem-active'
                  onClick={toggleDrawer('left', false)}
                >
                  <ListItemIcon>
                    <AssessmentIcon />
                  </ListItemIcon>
                  <ListItemText primary='Report รพ.' />
                </ListItem>
              )}

              {appData?.permission?.viewProvinceReport && (
                <ListItem
                  button
                  component={NavLink}
                  to='/chw-refer-report'
                  exact
                  className={`${classes.MuiButtonCustom} ${
                    replaceLocation(PageLocation).indexOf(replaceLocation('/chw-refer-report')) === 0 &&
                    'ListItem-active'
                  } `}
                  activeClassName='ListItem-active'
                  onClick={toggleDrawer('left', false)}
                >
                  <ListItemIcon>
                    <AssessmentIcon />
                  </ListItemIcon>
                  <ListItemText primary='Report จังหวัด' />
                </ListItem>
              )}

              <ListItem
                button
                component={NavLink}
                to='/online-hospital'
                exact
                className={`${classes.MuiButtonCustom} ${
                  replaceLocation(PageLocation).indexOf(replaceLocation('/online-hospital')) === 0 && 'ListItem-active'
                } `}
                activeClassName='ListItem-active'
                onClick={toggleDrawer('left', false)}
              >
                <ListItemIcon>
                  <ContactlessIcon />
                </ListItemIcon>
                <ListItemText primary='Online Hospital' />
              </ListItem>
            </List>
          </Collapse>
        </List>

        {appData?.permission?.viewBilling && (
          <List>
            <ListItem button onClick={handleClickBilling} className={classes.MuiButtonCustom}>
              <ListItemIcon>
                <ReceiptIcon />
              </ListItemIcon>
              <ListItemText primary='Billing' />
              {billingOpen ? <ExpandLess /> : <ExpandMore />}
            </ListItem>

            <Collapse in={billingOpen} timeout='auto' unmountOnExit>
              <List component='div' disablePadding>
                <ListItem
                  button
                  component={NavLink}
                  to='/billing-charge/to'
                  exact
                  className={`${classes.MuiButtonCustom} ${
                    replaceLocation(PageLocation).indexOf(replaceLocation('/billing-charge/to')) === 0 &&
                    'ListItem-active'
                  } `}
                  activeClassName='ListItem-active'
                  onClick={toggleDrawer('left', false)}
                >
                  <ListItemIcon>
                    <ContactlessIcon />
                  </ListItemIcon>
                  <ListItemText primary='Billing เรียกเก็บ' />
                </ListItem>

                <ListItem
                  button
                  component={NavLink}
                  to='/billing-charge/from'
                  exact
                  className={`${classes.MuiButtonCustom} ${
                    replaceLocation(PageLocation).indexOf(replaceLocation('/billing-charge/from')) === 0 &&
                    'ListItem-active'
                  } `}
                  activeClassName='ListItem-active'
                  onClick={toggleDrawer('left', false)}
                >
                  <ListItemIcon>
                    <ContactlessIcon />
                  </ListItemIcon>
                  <ListItemText primary='Billing ตามจ่าย' />
                </ListItem>

                <ListItem
                  button
                  component={NavLink}
                  to='/settings'
                  exact
                  className={`${classes.MuiButtonCustom} ${
                    replaceLocation(PageLocation).indexOf(replaceLocation('/settings')) === 0 && 'ListItem-active'
                  } `}
                  activeClassName='ListItem-active'
                  onClick={toggleDrawer('left', false)}
                >
                  <ListItemIcon>
                    <SettingsIcon />
                  </ListItemIcon>
                  <ListItemText primary='Settings' />
                </ListItem>
              </List>
            </Collapse>
          </List>
        )}

        <Divider />

        {appData?.permission?.userManagement && (
          <>
            <List>
              <ListItem
                button
                component={NavLink}
                to='/user-management'
                exact
                className={`${classes.MuiButtonCustom} ${
                  replaceLocation(PageLocation).indexOf(replaceLocation('/user-management')) === 0 && 'ListItem-active'
                } `}
                activeClassName='ListItem-active'
                onClick={toggleDrawer('left', false)}
              >
                <ListItemIcon>
                  <SupervisorAccountIcon />
                </ListItemIcon>
                <ListItemText primary='User Management' />
              </ListItem>
            </List>
          </>
        )}

        <Divider />
        <List>
          <ListItem button exact activeClassName='ListItem-active' onClick={openUserManualInNewTab}>
            <ListItemIcon>
              <InsertDriveFileIcon />
            </ListItemIcon>
            <ListItemText primary='User Manual' />
          </ListItem>
        </List>
      </Grid>
    </div>
  );

  const NavigationBarSet = (
    <div className={classes.root}>
      <AppBar position='fixed' className='MuiAppBar-color-custom'>
        <CssBaseline />
        <Toolbar className='app-tool-bar'>
          <Avatar className={classes.large6 + ' cursor-pointer'} onClick={toggleDrawer('left', true)}>
            <MenuIcon style={{ fontSize: '24px' }} />
          </Avatar>

          <Typography variant='h6' className='cursor-pointer font-family-custom' noWrap>
            <ListItem
              component={NavLink}
              to='/'
              exact
              className={classes.MuiButtonCustom + ' app-name-version'}
              activeClassName='ListItem-active'
              onClick={toggleDrawer('left', false)}
              style={{ paddingLeft: '2px' }}
            >
              EverHIE
            </ListItem>
          </Typography>

          <div className={classes.RightMenu}>
            <div className={classes.RightMenuIconAvatar}>
              <IconButton className='icon-button avatar-button' onClick={handleClick}>
                <Avatar>S</Avatar>
              </IconButton>

              <Menu
                id='profile-menu'
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                className='profile-menu'
              >
                <div className='profile-menu'>
                  <List>
                    <ListItem button>
                      <ListItemIcon>
                        <Avatar>S</Avatar>
                      </ListItemIcon>
                      <ListItemText primary={appData.loginData && appData.loginData.hospName} />
                    </ListItem>
                    <ListItem button>
                      <ListItemIcon>
                        <SettingsIcon />
                      </ListItemIcon>
                      <ListItemText primary='Setting' />
                    </ListItem>
                  </List>
                  <Divider />
                  <List>
                    <ListItem className={classes.MuiButtonCustom + ' margin-top-bottom-0px padding-top-bottom-0px'}>
                      <ListItemIcon>
                        <Brightness6Icon />
                      </ListItemIcon>
                      <FormControlLabel
                        className='margin-bottom-0px'
                        onClick={toggleDrawer('left', false)}
                        control={
                          <Switch
                            checked={localStorage.getItem('theme') === 'dark'}
                            onChange={toggleTheme()}
                            value='dark'
                          />
                        }
                        label='Dark Theme'
                      />
                    </ListItem>
                  </List>
                  <Divider />
                  <ListItem button onClick={logoutApp(appData?.loginData && appData.loginData.username)}>
                    <ListItemIcon>
                      <ExitToAppIcon />
                    </ListItemIcon>
                    <ListItemText primary='Log out' />
                  </ListItem>
                </div>
              </Menu>
            </div>
            <div className={classes.RightMenuIcon} id='terrsetse'>
              <BarNotify />
            </div>
            <div className={classes.RightMenuIcon}>
              <BarApp />
            </div>
          </div>
        </Toolbar>
      </AppBar>
    </div>
  );

  const [height, setHeight] = useState(window.innerHeight);

  const updateWidthAndHeight = () => {
    setHeight(window.innerHeight);
  };

  useEffect(() => {
    setPageLocationFc();
    window.addEventListener('resize', updateWidthAndHeight);
    return () => window.removeEventListener('resize', updateWidthAndHeight);
  });

  return (
    <div className='App'>
      <div>{NavigationBarSet}</div>
      <div className='navbar-icon'>
        <Avatar
          alt='Travis Howard'
          src={PjHIS}
          className={classes.large + ' cursor-pointer'}
          onClick={toggleDrawer('left', true)}
        />
      </div>
      <div>
        <Drawer color='secondary' open={state.left} onClose={toggleDrawer('left', false)}>
          {sideList('left', appData)}
        </Drawer>
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    appProperty: state.appProperty,
    appData: state.appData,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    appPropertySet: appPropertyData => dispatch(appPropertySet(appPropertyData)),
    fetchLogout: appData => dispatch(fetchLogout(appData)),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NavigationMain));
