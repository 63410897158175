import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Parser } from 'json2csv';
import axios from 'axios';

// styles
import { TableRow, TableCell, Container } from './styles';

// actions
import { fetchCategories } from '../../actions/Categories';
import { recalculateBilling } from '../../actions/Billings/RecalculateBilling';

// apis
import DeleteCategory from '../../api/Billings/DeleteCategory';

// components
import DatePickerDialog from '../Utility/DatePickerDialog';

// mui
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import LinearProgress from '@material-ui/core/LinearProgress';

// functions
import { getApiUrl } from "../../functions/Commons";

const Settings = props => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { appData } = useSelector(state => state);
  const { idToken, hospCode, categories } = appData;

  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [recalculateBillingDialogOpen, setRecalculateBillingDialogOpen] = useState(false);

  useEffect(() => {
    !modal && handleFetchCategories({ idToken, hospCode });
  }, [dispatch, idToken, hospCode, modal]);

  const handleFetchCategories = ({ idToken, hospCode }) => {
    dispatch(fetchCategories({ idToken, hospCode }));
  };

  const handleDelete = async () => {
    await DeleteCategory({ idToken, hospCode, categoryId: modal?.categoryId });

    setModal(false);
  };

  const exportBillingRules = async () => {
    const json2csvParser = new Parser();
    const result = await axios.get(`${getApiUrl()}/v1/billingrules/exportRules/${hospCode}`, {
      headers: { Authorization: `Bearer ${idToken}` },
    });

    const csv = json2csvParser.parse(result?.data.categories.length > 0 ? result.data.categories : { null: 'null' });
    const url = window.URL.createObjectURL(new Blob([csv]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'rules.csv');
    document.body.appendChild(link);
    link.click();
  };

  const handleRecalculateBilling = ({ idToken, monthIndex, hospCode }) => {
    recalculateBilling({ idToken, monthIndex, hospCode });

    alert(
      `เริ่มทำการคำนวณ Billing\n - เดือน: ${
        monthIndex + 1
      }\n - hospCode: ${hospCode}\nแล้วกรุณารอสักครู่แล้วตรวจสอบใหม่อีกครั้ง`
    );

    setRecalculateBillingDialogOpen(false);
  };

  return (
    <Container>
      <div className='header'>
        <h4>กลุ่มสิทธิ์เรียกเก็บ</h4>
        <div>
          <Button
            onClick={() => setRecalculateBillingDialogOpen(true)}
            className='recalculateBilling'
            variant='contained'
            size='small'
          >
            คำนวณ Billing ใหม่
          </Button>
          <Button onClick={exportBillingRules} className='export' variant='contained' size='small'>
            ดาวน์โหลดรายการยาและบริการ
          </Button>
          <Button
            onClick={() => history.push('/settings/edit-category')}
            variant='contained'
            color='primary'
            size='small'
          >
            เพิ่มกลุ่มสิทธิ์
          </Button>
        </div>
      </div>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ชื่อสิทธิ์หลัก</TableCell>
              <TableCell>รหัสสิทธิ์ pttype</TableCell>
              <TableCell align='right'></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {categories &&
              categories.map(row => (
                <TableRow key={row.code}>
                  <TableCell>{row.name}</TableCell>
                  <TableCell>{row.members && row.members.join(', ')}</TableCell>
                  <TableCell align='right'>
                    <span className='edit-cate'>
                      <Button
                        onClick={() =>
                          history.push(`/settings/edit-category/${row.code}`, {
                            category: row,
                          })
                        }
                        variant='contained'
                        size='small'
                      >
                        แก้ไข
                      </Button>
                    </span>
                    <Button
                      onClick={() => setModal({ categoryId: row._id, name: row.name })}
                      variant='contained'
                      size='small'
                      color='secondary'
                    >
                      ลบ
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Dialog open={modal} onClose={() => setModal(false)}>
        <DialogTitle>ลบรายการ {modal?.name}</DialogTitle>
        <DialogContent>
          <DialogContentText>ยืนยันที่จะลบ ?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button disabled={loading} onClick={() => setModal(false)} color='primary'>
            ยกเลิก
          </Button>
          <Button disabled={loading} onClick={e => handleDelete()} color='primary'>
            ยืนยัน
          </Button>
        </DialogActions>
        {loading && <LinearProgress />}
      </Dialog>

      <DatePickerDialog
        open={recalculateBillingDialogOpen}
        onClose={() => setRecalculateBillingDialogOpen(false)}
        onSubmit={e =>
          handleRecalculateBilling({ idToken, monthIndex: new Date(e?.selectedDate)?.getMonth(), hospCode })
        }
      />
    </Container>
  );
};

export default Settings;
