import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import orm from '../../models/index';

// actions
import { appPropertySet } from '../../actions/AppProperty';
import { fetchPatientsVisitsDetailsSet } from '../../actions/PatientsVisitsDetails';

// react-bootstrap
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

// mui
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';

const session = orm.session();

function PtPhysicalExam({ bid, appData, appPropertySet, fetchPatientsVisitsDetailsSet }) {
  const [visitData, setvisitData] = useState(null);
  const [bidData, setDataBid] = useState(false);

  const setDataBidFc = bid => {
    setDataBid(bid);
  };

  const setvisitDataFc = bid => {
    const visits = appData.emrData.visits;

    session.Visits.delete({
      where(record) {
        return record.bid === bid;
      },
    });

    for (var val of visits) {
      session.Visits.upsert(val);
    }

    let visitsData = session.Visits.all()
      .filter(Visits => Visits.bid === bid)
      .toRefArray();

    setvisitData({ visitsData });
  };

  useEffect(() => {
    if (bid !== bidData) {
      setDataBidFc(bid);
      setvisitDataFc(bid);
    }
  });

  return (
    <div>
      {visitData && (
        <div>
          {visitData.visitsData.map((items, index) => (
            <div key={index}>
              <Card variant='outlined' className='visit-detail-card'>
                <Row>
                  <Col lg={12}>
                    <h5>Physical Exam</h5>
                  </Col>
                </Row>
                <Row>
                  <Col lg={12}>
                    {items.visitVital.peHeent && (
                      <Grid container spacing={1} className='font-size-12px'>
                        <Grid item xs={2}>
                          HEENT
                        </Grid>
                        <Grid item xs={1}>
                          {items.visitVital.peHeent}
                        </Grid>
                        <Grid item xs={9}>
                          {items.visitVital.peHeentText}
                        </Grid>
                      </Grid>
                    )}

                    {items.visitVital.peHeart && (
                      <Grid container spacing={1} className='font-size-12px'>
                        <Grid item xs={2}>
                          HEART
                        </Grid>
                        <Grid item xs={1}>
                          {items.visitVital.peHeart}
                        </Grid>
                        <Grid item xs={9}>
                          {items.visitVital.peHeartText}
                        </Grid>
                      </Grid>
                    )}

                    {items.visitVital.peLung && (
                      <Grid container spacing={1} className='font-size-12px'>
                        <Grid item xs={2}>
                          LUNG
                        </Grid>
                        <Grid item xs={1}>
                          {items.visitVital.peLung}
                        </Grid>
                        <Grid item xs={9}>
                          {items.visitVital.peLungText}
                        </Grid>
                      </Grid>
                    )}

                    {items.visitVital.peAb && (
                      <Grid container spacing={1} className='font-size-12px'>
                        <Grid item xs={2}>
                          AB
                        </Grid>
                        <Grid item xs={1}>
                          {items.visitVital.peAb}
                        </Grid>
                        <Grid item xs={9}>
                          {items.visitVital.peAbText}
                        </Grid>
                      </Grid>
                    )}

                    {items.visitVital.peExt && (
                      <Grid container spacing={1} className='font-size-12px'>
                        <Grid item xs={2}>
                          EXT
                        </Grid>
                        <Grid item xs={1}>
                          {items.visitVital.peExt}
                        </Grid>
                        <Grid item xs={9}>
                          {items.visitVital.peExtText}
                        </Grid>
                      </Grid>
                    )}

                    {items.visitVital.peNeuro && (
                      <Grid container spacing={1} className='font-size-12px'>
                        <Grid item xs={2}>
                          NEURO
                        </Grid>
                        <Grid item xs={1}>
                          {items.visitVital.peNeuro}
                        </Grid>
                        <Grid item xs={9}>
                          {items.visitVital.peNeuroText}
                        </Grid>
                      </Grid>
                    )}

                    {items.visitVital.pe && (
                      <div>
                        <hr />
                        <Grid container spacing={1} className='font-size-14px'>
                          <Grid item xs={12}>
                            PE Text :
                            {items.visitVital.pe && (
                              <div style={{ paddingLeft: '8px' }}>
                                {items.visitVital.pe.split('\r').map((item2, index2) => (
                                  <div key={index2}> {item2}</div>
                                ))}
                              </div>
                            )}
                          </Grid>
                        </Grid>
                      </div>
                    )}
                  </Col>
                </Row>
              </Card>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

function mapStateToProps(state) {
  return {
    appProperty: state.appProperty,
    appData: state.appData,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    appPropertySet: appPropertyData => dispatch(appPropertySet(appPropertyData)),
    fetchPatientsVisitsDetailsSet: dataRequest => dispatch(fetchPatientsVisitsDetailsSet(dataRequest)),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PtPhysicalExam));
