import axios from 'axios';

// functions
import { getApiUrl } from "../../functions/Commons";

const FetchHospMain = ({ idToken, hospCode }) => {
  let dispatch = {};
  const url = `${getApiUrl()}/v1/billing/getcachehospmain?hospCode=${hospCode}`;

  return axios
    .get(url, { headers: { Authorization: `Bearer ${idToken}` } })
    .then(res => {
      dispatch = {
        hospmains: hospCode === '10685' ? res.data.result : res.data.result?.filter(item => item.hospCode !== hospCode),
        FetchingStatus: false,
      };

      return dispatch;
    })
    .catch(err => {
      dispatch = {
        FetchingStatus: false,
      };
      return dispatch;
    });
};

export default FetchHospMain;
