import axios from 'axios';

// functions
import { getApiUrl } from "../../functions/Commons";

const RecalculateBilling = ({ idToken, monthIndex, hospCode }) => {
  let dispatch = {};
  const url = `${getApiUrl()}/v1/billing/recalculateallbillingbymonth`;

  const body = {
    idToken,
    monthIndex,
    hospCode,
  };

  return axios
    .put(url, body, { headers: { Authorization: `Bearer ${idToken}` } })
    .then(res => {
      dispatch = {
        FetchingStatus: false,
      };

      return dispatch;
    })
    .catch(err => {
      dispatch = {
        FetchingStatus: false,
      };

      return dispatch;
    });
};

export default RecalculateBilling;
