import  axios from 'axios';
import { getApiUrl } from "../functions/Commons";
export const getEconsent = async(cid,token) => {
  try {
    const AuthStr = 'Bearer '.concat(token);
    const apiUrl = getApiUrl();
    const econsent = await axios.get(`${apiUrl}/v1/consent/${cid}`,{
      headers: { Authorization: AuthStr },
    });
    return econsent.data;
  } catch (error) {
    console.log('get econsent info error');
  }
}