import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import DayjsUtils from '@date-io/dayjs';
import 'dayjs/locale/th';

// actions
import { fetchBillingsCharge, fetchHospMain } from '../../actions/BillingsCharge';
import { fetchHospitalList } from '../../actions/UserManagement';

// styles
import { Container, TableRow, TableCell, Status } from './styles';

// functions
import { shortHospName, getBillingStatusConfig, formatNum } from '../../functions/FuncPerjer';

// utilities
import { ListboxComponent } from '../Utility/ListboxComponent';

// mui
import EventNoteIcon from '@material-ui/icons/EventNote';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import { DatePicker, MuiPickersUtilsProvider } from 'material-ui-thai-datepickers';
import { Autocomplete } from '@material-ui/lab';
import { CircularProgress, Grid, Typography } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';

const BillingsCharge = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { appData } = useSelector(state => state);
  const { idToken, hospmains, hospCode, billings, FetchingStatus, loginData, hospitalList } = appData;
  const asProvider = history.location.pathname.indexOf('/to') !== -1 ? true : false;

  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedHospmain, setSelectedHospmain] = useState({});
  const [provider, setProvider] = useState({});
  const [payer, setPayer] = useState({});
  const [hospitalListNotIncludeOwnHospCode, setHospitalListNotIncludeOwnHospCode] = useState([]);

  // fetch list of hospmains but if login role equal superadmin fetch list of hospital instead
  useEffect(() => {
    loginData?.role?.toUpperCase() === 'SUPERADMIN'
      ? handleFetchHospitalList({ idToken })
      : handleFetchHospMain({ idToken, hospCode });
  }, []);

  // fetch list of billing
  useEffect(() => {
    if (loginData?.role?.toUpperCase() === 'SUPERADMIN') {
      handleFetchBillingCharge({
        idToken,
        provider: provider?.hospCode,
        payer: payer?.hospCode,
        year: new Date(selectedDate).getFullYear(),
      });
    } else {
      if (typeof selectedHospmain === 'string') {
        handleFetchBillingCharge({
          idToken,
          provider: asProvider ? hospCode : selectedHospmain,
          payer: asProvider ? selectedHospmain : hospCode,
          year: new Date(selectedDate).getFullYear(),
        });
      }
    }
  }, [idToken, asProvider, hospCode, selectedDate, selectedHospmain, provider, payer, loginData]);

  // set dropdown of hospmains
  useEffect(() => {
    const billingCache = JSON.parse(localStorage.getItem('billing-charge'));

    if (billingCache) {
      setSelectedDate(billingCache?.selectedDate);
      setSelectedHospmain(billingCache?.selectedHospmain);
    } else if (hospmains?.length > 0) setSelectedHospmain(hospmains[0]?.hospCode);
  }, [hospmains]);

  // set dropdown of hospital list
  useEffect(() => {
    const billingCache = JSON.parse(localStorage.getItem('billing-charge'));
    const tmpHospitalList = hospitalList?.filter(item => item?.hospCode !== loginData?.hospCode);

    setHospitalListNotIncludeOwnHospCode(tmpHospitalList);

    if (billingCache) {
      setProvider(billingCache?.provider);
      setPayer(billingCache?.payer);
    } else if (hospitalList?.length > 0) {
      setProvider(tmpHospitalList[0]);
      setPayer(tmpHospitalList[0]);
    }
  }, [hospitalList]);

  const handleFetchHospitalList = ({ idToken }) => {
    dispatch(fetchHospitalList({ idToken }));
  };

  const handleFetchHospMain = ({ idToken, hospCode }) => {
    dispatch(fetchHospMain({ idToken, hospCode }));
  };

  const handleFetchBillingCharge = ({ idToken, provider, payer, year }) => {
    dispatch(fetchBillingsCharge({ idToken, provider, payer, year }));
  };

  const visitChargeSummary = ({ asProvider, selectedHospmain, monthIndex, selectedDate, provider, payer, role }) => {
    const billingCache = {
      selectedDate,
      selectedHospmain,
      provider,
      payer,
    };

    localStorage.setItem('billing-charge', JSON.stringify(billingCache));

    let hospmain = '';

    role?.toUpperCase() === 'SUPERADMIN'
      ? asProvider
        ? (hospmain = payer?.hospCode)
        : (hospmain = provider?.hospCode)
      : (hospmain = selectedHospmain);

    history.push({
      pathname: `${asProvider ? 'to' : 'from'}/${hospmain}/${monthIndex}`,
      state: { year: new Date(selectedDate)?.getFullYear(), provider, payer },
    });
  };

  return (
    <>
      <Container>
        <div className='header'>
          <EventNoteIcon className='header-icon' />
          <h5 className='title'>{asProvider ? 'เรียกเก็บ พ.ศ. ' : 'ตามจ่าย พ.ศ. '}</h5>
          <MuiPickersUtilsProvider utils={DayjsUtils} locale='th'>
            <DatePicker
              autoOk
              disableToolbar
              className='year-picker'
              size='small'
              views={['year']}
              value={selectedDate}
              inputVariant='outlined'
              variant='inline'
              yearOffset={543}
              minDate={new Date().setFullYear(new Date().getFullYear() - 10)}
              maxDate={new Date()}
              onChange={setSelectedDate}
            />
          </MuiPickersUtilsProvider>
        </div>

        {loginData?.role?.toUpperCase() === 'SUPERADMIN' && provider?.hospCode && payer?.hospCode && (
          <>
            <Grid container spacing={1} direction={!asProvider && 'row-reverse'}>
              <Grid item xs={12} md={6}>
                <Autocomplete
                  disableListWrap
                  loading={!hospitalListNotIncludeOwnHospCode[0]}
                  ListboxComponent={ListboxComponent}
                  options={hospitalListNotIncludeOwnHospCode}
                  defaultValue={{
                    hospCode: provider?.hospCode,
                    hospName: provider?.hospName,
                  }}
                  onChange={(option, value) => setProvider(value)}
                  getOptionLabel={option => `${option.hospCode} : ${option.hospName}`}
                  renderInput={params => (
                    <TextField
                      {...params}
                      fullWidth
                      size='small'
                      label={'รพ. เรียกเก็บ'}
                      variant='outlined'
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {!hospitalListNotIncludeOwnHospCode[0] ? (
                              <CircularProgress color='inherit' size={20} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <Autocomplete
                  disableListWrap
                  loading={!hospitalListNotIncludeOwnHospCode[0]}
                  ListboxComponent={ListboxComponent}
                  options={hospitalListNotIncludeOwnHospCode}
                  defaultValue={{
                    hospCode: payer?.hospCode,
                    hospName: payer?.hospName,
                  }}
                  onChange={(option, value) => setPayer(value)}
                  getOptionLabel={option => `${option.hospCode} : ${option.hospName}`}
                  renderInput={params => (
                    <TextField
                      {...params}
                      fullWidth
                      size='small'
                      label={'รพ. ตามจ่าย'}
                      variant='outlined'
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {!hospitalListNotIncludeOwnHospCode[0] ? (
                              <CircularProgress color='inherit' size={20} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </>
        )}

        {!FetchingStatus && hospmains && selectedHospmain && (
          <>
            <div className='table-head'>
              <h4>
                <b>{shortHospName(hospmains?.filter(item => item.hospCode === selectedHospmain)[0]?.hospName)}</b>
              </h4>
              <Select variant='outlined' value={selectedHospmain} onChange={e => setSelectedHospmain(e?.target?.value)}>
                {hospmains?.map(hosp => (
                  <MenuItem value={hosp?.hospCode}>{`${hosp?.hospCode}: ${hosp?.hospName}`}</MenuItem>
                ))}
              </Select>
            </div>
            {selectedHospmain === '00935' && hospCode === '10685' && (
              <Typography className='notice' color='secondary' variant='caption'>
                ***กรณีเรียกเก็บ/ตามจ่าย รพสต.บ้านคลองบางปิ้ง กรุณาเลือก 10685: รพ.สมุทรปราการ สิทธิ uc รพสต
              </Typography>
            )}
          </>
        )}
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align='center'>เดือน</TableCell>
              <TableCell align='right'>จำนวนผู้ป่วย</TableCell>
              <TableCell align='right'>ยอดเงินตามจริง (฿)</TableCell>
              <TableCell align='right'>ยอดที่คำนวณ (฿)</TableCell>
              <TableCell align='right'>ยอดที่เรียกเก็บ (฿)</TableCell>
              <TableCell align='right'>สถานะ</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!FetchingStatus && billings && billings?.length > 0 ? (
              billings.map(row => (
                <TableRow
                  key={row._id}
                  onClick={() =>
                    visitChargeSummary({
                      asProvider,
                      selectedHospmain,
                      monthIndex: row.monthIndex + 1,
                      selectedDate,
                      provider,
                      payer,
                      role: loginData?.role,
                    })
                  }
                >
                  <TableCell align='center' component='th' scope='row'>
                    {new Date(row.billingDate?.substr(0, 10)).toLocaleDateString('th-TH', {
                      month: 'short',
                      year: 'numeric',
                    })}
                  </TableCell>
                  <TableCell align='right'>{row.totalPatients}</TableCell>
                  <TableCell align='right'>{formatNum(row.totalPrice)}</TableCell>
                  <TableCell align='right'>{formatNum(row.totalCalcPrice)}</TableCell>
                  <TableCell align='right'>{formatNum(row.totalFinalPrice)}</TableCell>
                  <TableCell align='right'>
                    <Status color={getBillingStatusConfig(row.status).color}>
                      {getBillingStatusConfig(row.status).text}
                    </Status>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={6} align='center' component='th' scope='row'>
                  ไม่พบข้อมูล
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </Container>
    </>
  );
};

export default BillingsCharge;
