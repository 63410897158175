import React, { Component } from 'react';
import { connect } from 'react-redux';
import Iframe from 'react-iframe';

// actions
import { appPropertySet } from '../../actions/AppProperty';
import { fetchRefers } from '../../actions/ReferDatas';

// react-bootstrap
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

// mui
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Grid from '@material-ui/core/Grid';

class GeospatialOutbreak extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className='margin-bottom-8px'>
        <AppBar
          position='fixed'
          className='box-shadow-custom MuiAppBar-color-custom patient-main'
          style={{ top: '55px' }}
        >
          <Toolbar variant='dense' className='refer-bar'>
            <div className='text-align-left' style={{ width: '100%' }}>
              <Row>
                <Col lg={6} className='mb-lg-0 mb-sm-2 mb-md-2 mb-xs-2'>
                  <Row>
                    <Grid container item xs={12}>
                      <h6 className='margin-bottom-0px margin-top-8px' style={{ marginLeft: '4px' }}>
                        Geospatial Outbreak
                      </h6>
                    </Grid>
                  </Row>
                </Col>
              </Row>
            </div>
          </Toolbar>
        </AppBar>
        <div style={{ overflow: 'auto' }}>
          <div style={{ width: '1200px', marginLeft: 'auto', marginRight: 'auto' }}>
            <Iframe
              url='https://storage.googleapis.com/siametrics-ever-share/emt_map.html'
              width='100%'
              height={this.props.height - 135 + 'px'}
              id='emt1'
              frameborder='0'
              className='geospatial-outbreak-class'
              allowfullscreen
            />
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    appProperty: state.appProperty,
    appData: state.appData,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    appPropertySet: appPropertyData => dispatch(appPropertySet(appPropertyData)),
    fetchRefers: dataRequest => dispatch(fetchRefers(dataRequest)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(GeospatialOutbreak);
