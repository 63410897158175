import axios from 'axios';
import config from 'react-global-configuration';

// functions
import { getApiUrl } from "../functions/Commons";

export default (username, password) => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      let re = config.get('mailHost');
      username = username.replace(re, '');
      // username = username + config.get('mailHost')

      let bodyData = {
        username: username,
        password: password,
        returnSecureToken: true,
      };

      axios
        .post(`${getApiUrl()}/auth/login`, bodyData)
        .then(res => {
          if (res.status === 200) {
            let data = {
              loginStatus: true,
              status: res.data.status,
              idToken: res.data.token,
              FetchingStatus: false,
            };

            return resolve(data);
          } else {
            let data = {
              loginStatus: true,
              status: res.data.status,
              idToken: res.data.token,
              FetchingStatus: false,
            };
            return resolve(data);
          }
        })
        .catch(err => {
          console.log('AXIOS ERROR: User Login error !');
          // return reject(err.message)
          return reject(err.response);
        });
    }, 200);
  });
};
