import React, { Component } from 'react';
import { connect } from 'react-redux';
import orm from '../../models/index';

// actions
import { appPropertySet } from '../../actions/AppProperty';
import { fetchRefers } from '../../actions/ReferDatas';

// react-bootstrap
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

// components
import ReferInPercentChart from './ReferInPercentChart';
import ReferInQtyChart from './ReferInQtyChart';
import ReferInDiagQtyChart from './ReferInDiagQtyChart';
import ReferInDiag4BranchChart from './ReferInDiag4BranchChart';

// functions
import { shortHospName } from '../../functions/FuncPerjer';

// mui
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Alert from '@material-ui/lab/Alert';

const session = orm.session();

class ReferInReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hospCodeData: [],
      ReferDatas: [],
      ReferPendingData: [],
      ReferAcceptData: [],
      ReferRejectData: [],
      ReferPtData: [],
    };
    this.ReferProcess = this.ReferProcess.bind(this);
  }

  ReferProcess() {
    const referDb = this.state.ReferDatas;

    session.ReferInDatas.delete({
      where(record) {
        return record._id !== '';
      },
    });

    session.ReferHosp.delete({
      where(record) {
        return record.id !== '';
      },
    });

    session.TempData.delete({
      where(record) {
        return record.id !== '';
      },
    });

    for (var val of referDb) {
      session.ReferInDatas.upsert(val);

      session.ReferHosp.upsert({
        id: val.fromHospCode,
        hospName: val.fromHospName ? shortHospName(val.fromHospName) : val.fromHospCode,
      });

      session.TempData.upsert({
        id: val.fromHospCode + val.hn,
        ptname: val.ptname,
      });
    }

    let hospPtData2 = session.TempData.all()
      .filter(TempData => TempData.id !== '')
      .toRefArray();

    this.setState({ ReferPtData: hospPtData2 });

    let hospCodeData2 = session.ReferHosp.all()
      .filter(ReferHosp => ReferHosp.id !== '')
      .orderBy(ReferHosp => ReferHosp.hospName, 'asc')
      .toRefArray();

    this.setState({ hospCodeData: hospCodeData2 });

    let referAcceptData = session.ReferInDatas.all()
      .filter(ReferInDatas => ReferInDatas.status === 'Complete')
      .toRefArray();

    this.setState({ ReferAcceptData: referAcceptData });

    let referRejectData = session.ReferInDatas.all()
      .filter(ReferInDatas => ReferInDatas.status === 'Rejected')
      .toRefArray();

    this.setState({ ReferRejectData: referRejectData });

    let referPendingData = session.ReferInDatas.all()
      .filter(ReferInDatas => ReferInDatas.status === 'Pending')
      .toRefArray();

    this.setState({ ReferPendingData: referPendingData });
  }

  componentDidMount() {
    if (this.state.ReferDatas !== this.props.ReferDatas) {
      this.setState({ ReferDatas: this.props.ReferDatas });
      setTimeout(() => {
        this.ReferProcess();
      }, 500);
    }
  }

  componentDidUpdate() {
    if (this.state.ReferDatas !== this.props.ReferDatas) {
      this.setState({ ReferDatas: this.props.ReferDatas });

      setTimeout(() => {
        this.ReferProcess();
      }, 500);
    }
  }

  render() {
    return (
      <div>
        {this.props.ReferDatas?.length > 0 && (
          <div>
            <Card className='card-refer margin-bottom-8px'>
              <CardContent>
                <Row>
                  <Col lg={3} className='mb-lg-0 mb-sm-2 mb-md-2 mb-xs-2'>
                    <Alert variant='filled' severity='info'>
                      <h6>
                        <strong>
                          Refer In : {this.state.ReferDatas && this.state.ReferDatas.length} ครั้ง /{' '}
                          {this.state.ReferPtData.length} คน
                        </strong>
                      </h6>
                    </Alert>
                  </Col>
                  <Col lg={3} className='mb-lg-0 mb-sm-2 mb-md-2 mb-xs-2'>
                    <Alert variant='filled' severity='warning'>
                      <h6>
                        <strong>
                          Pending : {this.state.ReferPendingData && this.state.ReferPendingData.length} ครั้ง
                        </strong>
                      </h6>
                    </Alert>
                  </Col>
                  <Col lg={3} className='mb-lg-0 mb-sm-2 mb-md-2 mb-xs-2'>
                    <Alert variant='filled' severity='success'>
                      <h6>
                        <strong>
                          Accept : {this.state.ReferAcceptData && this.state.ReferAcceptData.length} ครั้ง
                        </strong>
                      </h6>
                    </Alert>
                  </Col>
                  <Col lg={3} className='mb-lg-0 mb-sm-2 mb-md-2 mb-xs-2'>
                    <Alert variant='filled' severity='error'>
                      <h6>
                        <strong>
                          Reject : {this.state.ReferRejectData && this.state.ReferRejectData.length} ครั้ง
                        </strong>
                      </h6>
                    </Alert>
                  </Col>
                </Row>
              </CardContent>
            </Card>
            <Row>
              <Col lg={6} className='margin-bottom-8px'>
                <ReferInQtyChart
                  referInData={this.state.ReferDatas}
                  StartDate={this.props.StartDate}
                  EndDate={this.props.EndDate}
                />
              </Col>
              <Col lg={6} className='margin-bottom-8px'>
                <ReferInPercentChart
                  referInData={this.state.ReferDatas}
                  StartDate={this.props.StartDate}
                  EndDate={this.props.EndDate}
                />
              </Col>
            </Row>

            <Row>
              <Col lg={12} className='margin-bottom-8px'>
                <ReferInDiag4BranchChart
                  referInData={this.state.ReferDatas}
                  StartDate={this.props.StartDate}
                  EndDate={this.props.EndDate}
                />
              </Col>
            </Row>
            <Row>
              <Col lg={12} className='margin-bottom-8px'>
                <ReferInDiagQtyChart
                  referInData={this.state.ReferDatas}
                  StartDate={this.props.StartDate}
                  EndDate={this.props.EndDate}
                />
              </Col>
            </Row>
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    appProperty: state.appProperty,
    appData: state.appData,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    appPropertySet: appPropertyData => dispatch(appPropertySet(appPropertyData)),
    fetchRefers: dataRequest => dispatch(fetchRefers(dataRequest)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ReferInReport);
