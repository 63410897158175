import axios from 'axios';

// functions
import { getApiUrl } from '../../functions/Commons';

export default dataRequest => {
  return new Promise((resolve, reject) => {
    setTimeout(async () => {
      const { idToken, monthIndex, categoryCode, categoryName } = dataRequest;

      const result = await axios.post(`${getApiUrl()}/v1/billing/recalculateAndGetSummary`, dataRequest, {
        headers: { Authorization: `Bearer ${idToken}` },
      });

      const billingData = {
        calculateSummary: result.data.result.data[monthIndex].categories.find(
          category => category.categoryCode === categoryCode && category.categoryName === categoryName
        ),
        FetchingStatus: false,
      };

      return resolve(billingData);
    }, 200);
  });
};
