import pdfMake from 'pdfmake/build/pdfmake';
import { hospDataFromHospCode } from '../../functions/FuncPerjer';
import pdfFonts from '../fonts/vfs_fonts';
import bahttext from './BAHTTEXT.js';
import { garudaDataUrl } from '../dataUrl';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

pdfMake.fonts = {
  THSarabunNew: {
    normal: 'THSarabunNew.ttf',
    bold: 'THSarabunNew-Bold.ttf',
    italics: 'THSarabunNew-Italic.ttf',
    bolditalics: 'THSarabunNew-BoldItalic.ttf',
  },
};
function thaiNumber(num) {
  var array = {
    1: '๑',
    2: '๒',
    3: '๓',
    4: '๔',
    5: '๕',
    6: '๖',
    7: '๗',
    8: '๘',
    9: '๙',
    0: '๐',
  };
  var str = num.toString();
  for (var val in array) {
    str = str.split(val).join(array[val]);
  }
  return str;
}

function printUC(data, toHospName, datepdf, fromHospCode) {
  var thismonth = thaiNumber(new Date().toLocaleDateString('th-TH', { month: 'long', year: 'numeric' }));
  var billmonthandyear = thaiNumber(datepdf.toLocaleDateString('th-TH', { month: 'long', year: 'numeric' }));
  var totalpatient = thaiNumber(data.totalPatients);
  var finalprice = thaiNumber(
    Number(data.totalFinalPrice)
      .toFixed(2)
      .replace(/\d(?=(\d{3})+\.)/g, '$&,')
  );
  var bahttexteiei = bahttext(data.totalFinalPrice);

  const fromHospData = hospDataFromHospCode(fromHospCode);

  var docDefinition = {
    content: [
      {
        columns: [
          {
            text: `\n\nที่  สป ๐๐๓๒.${fromHospData?.headerNumber}/`,
            style: 'header',
          },
          {
            image: garudaDataUrl(),
            width: 80,
            height: 80,
          },
          [
            { text: `\n\n${fromHospData?.hospName || ''}`, style: 'tab' },
            { text: fromHospData?.address[0] || '', style: 'tab' },
            { text: fromHospData?.address[1] || '', style: 'tab' },
          ],
        ],
      },
      {
        text: thismonth,
        style: 'date',
      },
      {
        text: 'เรื่อง ขอรับเงินค่าบริการทางการแพทย์ผู้ป่วยนอก สิทธิประกันสุขภาพถ้วนหน้า ',
        style: 'subject',
      },
      { text: 'เรียน ผู้อำนวยการ' + toHospName + '', style: 'to' },
      {
        text: [
          { text: '.                ', color: 'white' },
          {
            text: `${fromHospData?.hospName} ขอเบิกเงินค่ารักษาพยาบาลผู้ป่วยโครงการบัตรประกันสุขภาพถ้วนหน้า ของ ${toHospName} ที่มารับบริการ ณ ${fromHospData?.hospName} ประจำเดือน ${billmonthandyear} จำนวน ${totalpatient} ราย เป็นจำนวนเงิน ${finalprice} บาท (${bahttexteiei})`,
          },
        ],
      },
      {
        text: [
          { text: '.                ', color: 'white' },
          {
            text: `โปรดสั่งจ่ายค่ารักษาพยาบาลผ่าน ${fromHospData?.bank?.uc?.bankName || ''} ชื่อบัญชี "${
              fromHospData?.bank?.uc?.accountName || ''
            }" เลขที่ ${fromHospData?.bank?.uc?.accountNumber || ''}`,
          },
        ],
      },
      {
        text: [
          { text: '.                ', color: 'white' },
          { text: 'จึงเรียนมาเพื่อโปรดทราบและพิจารณาดำเนินการต่อไป' },
        ],
      },
      {
        columns: [
          {},
          [
            { text: '\n\nขอแสดงความนับถือ', style: 'main4' },
            {
              text: `\n\n(${fromHospData?.signature?.name || ''})`,
              style: 'main5',
            },
            { text: fromHospData?.signature?.position || '', style: 'main6' },
            {
              text: fromHospData?.signature?.subPosition || '',
              style: 'main7',
            },
          ],
        ],
      },
      {
        stack: [
          `\n\n\n\n\n\n${fromHospData?.footer?.name || ''}`,
          {
            text: fromHospData?.footer?.tel && 'โทร. ' + fromHospData?.footer?.tel,
          },
          {
            text: fromHospData?.footer?.fax && 'โทรสาร. ' + fromHospData?.footer?.fax,
          },
          {
            text: fromHospData?.footer?.copy && 'สำเนาส่ง ' + fromHospData?.footer?.copy,
          },
        ],
        style: 'footer',
      },
    ],
    defaultStyle: {
      font: 'THSarabunNew',
      fontSize: 16,
    },
    styles: {
      header: { alignment: 'left' },
      date: { alignment: 'right', margin: [0, 0, 122, 0] },
      tab: { margin: [60, 0, 0, 0] },
      main4: { alignment: 'center' },
      main5: { alignment: 'center' },
      main6: { alignment: 'center' },
      main7: { alignment: 'center' },
    },
  };
  pdfMake.createPdf(docDefinition).open();
}

export default printUC;
