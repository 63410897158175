import { APPDATA } from '../constants/appData';
import Refers from '../api/Refers';
import ChwReport from '../api/Report/ChwReport';

export function fetchRefers(dataRequest) {
  return {
    type: APPDATA,
    payload: Refers(dataRequest),
  };
}

export function fetchChwRefers(dataRequest) {
  return {
    type: APPDATA,
    payload: ChwReport(dataRequest),
  };
}
