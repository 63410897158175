import axios from 'axios';

// functions
import { getApiUrl } from "../../functions/Commons";

export default async body => {
  const { billingItemId, idToken, author, message, provider, payer, year, categoryId } = body;
  try {
    const result = await axios.post(
      `${getApiUrl()}/v1/billingitems/reject/${billingItemId}`,
      {
        author,
        message,
        provider,
        payer,
        year,
        categoryId,
      },
      { headers: { Authorization: `Bearer ${idToken}` } }
    );
    return result;
  } catch (e) {
    throw new Error(e);
  }
};
