import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import orm from '../../models/index';

// actions
import { appPropertySet } from '../../actions/AppProperty';
import { fetchPatientsVisitsDetailsSet } from '../../actions/PatientsVisitsDetails';

// react-bootstrap
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Alert from 'react-bootstrap/Alert';

// functions
import { ThaiDateTime } from '../../functions/FuncDateTimes';

// mui
import Card from '@material-ui/core/Card';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const session = orm.session();

function PtXray({ bid, an, dataType, appData, appPropertySet, fetchPatientsVisitsDetailsSet }) {
  const [visitData, setvisitData] = useState(null);
  const [bidData, setDataBid] = useState(false);
  const [pageData, setPage] = useState(null);

  const setDataBidFc = bid => {
    setDataBid(bid);
  };

  const setvisitDataFc = bid => {
    const visits = appData.emrData.visits;

    session.Visits.delete({
      where(record) {
        return record.bid === bid;
      },
    });

    for (var val of visits) {
      session.Visits.upsert(val);
    }

    let visitsData = [];

    if (dataType === 'OPD') {
      visitsData = session.Visits.all()
        .filter(Visits => Visits.bid === bid)
        // .filter((Visits) => Visits.class === 'AMB')
        .toRefArray();
    }

    if (dataType === 'IPD') {
      visitsData = session.Visits.all()
        .filter(Visits => Visits.an === an)
        .filter(Visits => Visits.class === 'IMP')
        .toRefArray();
    }

    let xrayItem = [];

    if (visitsData && visitsData.length > 0 && visitsData[0].xrays) {
      xrayItem = visitsData[0].xrays;
    }

    let Pag = [];

    let nn = 1;
    for (var val2 of xrayItem) {
      if (dataType === 'OPD' && val2.vn !== '') {
        Pag.push(val2);
      }

      if (dataType === 'IPD' && val2.an !== '') {
        Pag.push(val2);
      }

      nn = nn + 1;
    }

    setPage(Pag);

    setvisitData({ visitsData });
  };

  useEffect(() => {
    if (bid !== bidData) {
      setDataBidFc(bid);
      setvisitDataFc(bid);
    }
  });

  return (
    <div>
      {visitData && (
        <div>
          {visitData.visitsData.map((items, index) => (
            <div key={index}>
              {items.xrays && (
                <Card variant='outlined' className='visit-detail-card'>
                  <Row>
                    <Col lg={12}>
                      <h5>X-Ray ({dataType})</h5>
                    </Col>
                  </Row>
                  <div
                    style={{
                      maxHeight: '500px',
                      overflowX: 'hidden',
                      overflowY: 'auto',
                    }}
                    className='overflow-scrolling-touch'
                  >
                    <Row>
                      {pageData.map((itemsXrayHead, indexXrayHead) =>
                        itemsXrayHead.xrayReportData.map((itemsXrayReport, indexXrayReport) => (
                          <Col lg={6} key={indexXrayReport}>
                            <div className='font-size-14px'>
                              <Alert variant='success'>
                                <Row>
                                  <Col lg={12}>
                                    <div>
                                      <div>
                                        {indexXrayReport + 1}. {}
                                        {itemsXrayReport.requestDateTime && itemsXrayReport.xrayItemsName}
                                      </div>
                                      <div>
                                        Request Date :{' '}
                                        {itemsXrayReport.requestDateTime &&
                                          ThaiDateTime(itemsXrayReport.requestDateTime)}
                                      </div>
                                      <div className='margin-bottom-4px'>
                                        Report Date :{' '}
                                        {itemsXrayReport.reportDateTime && ThaiDateTime(itemsXrayReport.reportDateTime)}
                                      </div>
                                      {itemsXrayReport.reportText && (
                                        <div>
                                          <ExpansionPanel className='MuiAppBar-color-custom '>
                                            <ExpansionPanelSummary
                                              expandIcon={<ExpandMoreIcon className='app-text-color' />}
                                              aria-controls={'xray' + indexXrayReport}
                                              id={'xray' + indexXrayReport}
                                              style={{
                                                height: '40px',
                                                minHeight: '40px',
                                              }}
                                            >
                                              <Typography>Report :</Typography>
                                            </ExpansionPanelSummary>
                                            <ExpansionPanelDetails>
                                              <Typography variant='caption'>
                                                {itemsXrayReport.reportText && (
                                                  <div
                                                    style={{
                                                      paddingLeft: '8px',
                                                    }}
                                                  >
                                                    {itemsXrayReport.reportText.split('\r').map((item2, index2) => (
                                                      <div key={index2}> {item2}</div>
                                                    ))}
                                                  </div>
                                                )}
                                              </Typography>
                                            </ExpansionPanelDetails>
                                          </ExpansionPanel>
                                        </div>
                                      )}
                                    </div>
                                  </Col>
                                </Row>
                              </Alert>
                            </div>
                          </Col>
                        ))
                      )}
                    </Row>
                  </div>
                </Card>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

function mapStateToProps(state) {
  return {
    appProperty: state.appProperty,
    appData: state.appData,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    appPropertySet: appPropertyData => dispatch(appPropertySet(appPropertyData)),
    fetchPatientsVisitsDetailsSet: dataRequest => dispatch(fetchPatientsVisitsDetailsSet(dataRequest)),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PtXray));
