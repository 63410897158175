import React from 'react';
import { useForm } from 'react-hook-form';

// mui
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';

export default function ReferAcceptDialog(props) {
  const { handleSubmit } = useForm();

  const onSubmit = referData => {
    props.onSubmit();
  };

  return (
    <Dialog
      fullWidth
      maxWidth='sm'
      open={props.open}
      onClose={() => props.onClose()}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      className='refer-dialog-accept'
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle id='alert-dialog-title'>
          <strong>ยืนยันการรับ Refer</strong>
        </DialogTitle>
        <DialogContent>
          {props.cid && <DialogContentText id='alert-dialog-description'>CID : {props.cid}</DialogContentText>}
          {props.ptname && (
            <DialogContentText id='alert-dialog-description'>ผู้ป่วย : {props.ptname}</DialogContentText>
          )}
          {props.hospName && (
            <DialogContentText id='alert-dialog-description'>จาก : {props.hospName}</DialogContentText>
          )}
        </DialogContent>
        <DialogActions>
          <Button type='submit' color='primary' variant='contained'>
            ยืนยันการรับ Refer
          </Button>
          <Button onClick={() => props.onClose()} color='secondary' variant='contained'>
            ยกเลิก
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
