export const SUPERADMIN = 'SUPERADMIN';
export const ADMIN = 'ADMIN';
export const DOCTOR = 'DOCTOR';
export const MANAGER = 'MANAGER';
export const NURSE = 'NURSE';
export const PATIENT_ASSISTANT = 'PATIENT_ASSISTANT';
export const REFERRAL_CENTER = 'REFERRAL_CENTER';
export const REGISTRATION_CENTER = 'REGISTRATION_CENTER';
export const INSURANCE_CENTER = 'INSURANCE_CENTER';

export const roles = [
  // { label: 'ซูเปอร์แอดมิน', value: 'superadmin' },
  { label: 'แอดมิน', value: ADMIN },
  { label: 'แพทย์', value: DOCTOR },
  { label: 'ผู้บริหาร', value: MANAGER },
  { label: 'พยาบาล', value: NURSE },
  { label: 'ผู้ช่วยเหลือคนไข้', value: PATIENT_ASSISTANT },
  { label: 'ศูนย์ Refer', value: REFERRAL_CENTER },
  { label: 'ห้องบัตร', value: REGISTRATION_CENTER },
  { label: 'ศูนย์ประกัน', value: INSURANCE_CENTER },
];
