import { Model, many } from 'redux-orm';

class Book extends Model {}
Book.modelName = 'Book';
Book.fields = {
  author_ids: many({
    to: 'Author',
    as: 'authors',
    relatedName: 'books',
  }),
};

export default Book;
