import React, { Component } from 'react';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { connect } from 'react-redux';

class AppBackdrop extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
    this.handleClose = this.handleClose.bind(this);
    this.handleOpen = this.handleOpen.bind(this);
  }

  handleOpen() {
    this.setState({ open: true });
  }

  handleClose() {
    this.setState({ open: false });
  }

  render() {
    if (this.props.appData && this.props.appData.FetchingStatus) {
      if (this.state.open !== true) {
        setTimeout(() => {
          this.handleOpen();
        }, 100);
      }
    } else {
      if (this.state.open === true) {
        setTimeout(() => {
          this.handleClose();
        }, 500);
      }
    }

    return (
      <div>
        <Backdrop open={this.state.open} className='app-backdrop'>
          <CircularProgress color={localStorage.getItem('theme') === 'dark' ? 'inherit' : 'secondary'} size={80} />
        </Backdrop>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    appProperty: state.appProperty,
    appData: state.appData,
  };
}

export default connect(mapStateToProps)(AppBackdrop);
